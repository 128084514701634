import React from 'react';
import { CopyToClipboard as VendorCopyToClipboard } from 'react-copy-to-clipboard';
import { Notifications } from '@piwikpro/notification-crate';
import { Button, withTranslation } from '@piwikpro/ui-components';
import { withInject } from '../../Platform';

interface InjectedProps {
  notifications: Notifications
}

interface Props extends InjectedProps {
  text: string | null | undefined
  t: (key: string) => string
}

class CopyToClipboard extends React.Component<Props> {
  handleCopyToClipboard = (text: string, result: boolean) => {
    const { notifications, t } = this.props;

    if (result) {
      notifications.push({
        text: t('notifications.copied.success'),
        type: 'success',
      });
    } else {
      notifications.push({
        text: t('notifications.copied.error'),
        type: 'error',
      });
    }
  };

  render() {
    const { text, t } = this.props;

    return (
      <VendorCopyToClipboard
        text={text || ''}
        onCopy={this.handleCopyToClipboard}
      >
        <Button appearance="simple" text={t('copyText')} icon="clipboard" disabled={text?.length === 0} />
      </VendorCopyToClipboard>
    );
  }
}

export default withInject<InjectedProps>({
  notifications: 'NotificationCrate.notifications',
})(withTranslation('components')(CopyToClipboard));
