import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '@piwikpro/http-crate/interfaces';
import { Notifications } from '@piwikpro/notification-crate';
import { Session } from '../Session';

@Service()
export class Auth implements Interceptable {
  constructor(
    @inject('AuthCrate.session') private readonly session: Session,
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  async intercept<T>(req: Required<IRequest<T>>, next: NextFunction): Promise<T> {
    let resp;

    try {
      resp = await next(req);
    } catch (err: any) {
      if (err.name === 'UnauthorizedRequestError' && !req.withoutRedirect) {
        this.notifications.push({
          text: 'components:toast.session-expired.title',
          description: 'components:toast.session-expired.description',
          type: 'error',
          autoCloseSeconds: 5,
          actions: [
            {
              id: 'ok',
              text: 'components:toast.session-expired.action',
              onClick: () => this.session.login(),
            },
          ],
          onClose: () => this.session.login(),
        });
      }

      throw err;
    }

    return resp;
  }
}
