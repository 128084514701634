/* eslint-disable no-case-declarations */
import React from 'react';
import { Container, AsyncContainerModule } from 'inversify';
import { StoreManager } from '@piwikpro/store';
import {
  ICrateConstructor,
  IRegistry,
  ICrate,
  ICrateBaseSettings,
} from './Crate';
import { Logger } from './Logger';
import { ConfigService } from './ConfigService';
import { LazyCrate } from './lazy';

export interface IDependencyMap {
  [key: string]: Dependency
}

/**
 * Responsible for loading one Crate only
 */
export class Dependency {
  private loaded: boolean = false;

  constructor(public readonly Crate: ICrateConstructor<any>) { }

  async load(
    storeManager: StoreManager,
    container: Container,
    logger: Logger,
    config: ConfigService,
    panels: React.ComponentClass[],
    providers: React.ComponentClass[],
  ): Promise<void> {
    logger.debug(`Loading "${this.Crate.settings.name}" crate`);

    if (this.Crate.settings.reducers) {
      storeManager.registerReducers(this.Crate.settings.reducers);

      logger.debug(`Register "${Object.keys(this.Crate.settings.reducers).join(', ')}" reducers`);
    }

    if (this.Crate.settings.reduxMiddleware) {
      storeManager.registerMiddleware(this.Crate.settings.reduxMiddleware);
    }

    if (this.Crate.settings.panels) {
      panels.push(...this.Crate.settings.panels);
    }

    if (this.Crate.settings.providers) {
      providers.push(...this.Crate.settings.providers);
    }

    // All container modules are asynchronous
    if (
      this.Crate.settings.registry
      || this.Crate.settings.services
      || this.Crate.settings.components
    ) {
      await container.loadAsync(new AsyncContainerModule(async (bind, unbind, isBound) => {
        if (this.Crate.settings.registry) {
          await (this.Crate.settings.registry as IRegistry)(bind, isBound, config);
        }

        if (this.Crate.settings.services) {
          this.Crate.settings.services.forEach((provider: any) => {
            switch (provider.type) {
              case 'factory':
                const binding = bind(
                  [this.Crate.settings.name, provider.name]
                    .join('.'),
                )
                  .toDynamicValue(
                    () => provider.provide({ getService: (name: string) => container.get(name) }),
                  );

                if (provider.scope !== 'dynamic') {
                  binding.inSingletonScope();
                }
                break;
              case 'constant':
                bind([this.Crate.settings.name, provider.name].join('.')).toConstantValue(provider.provide);
                break;
              default:
                bind([this.Crate.settings.name, provider.name].join('.')).to(provider.provide).inSingletonScope();
                break;
            }
          });
        }

        if (this.Crate.settings.components) {
          this.Crate.settings.components.forEach((provider: any) => {
            bind(`${this.Crate.settings.name}.components.${provider.name}`).toConstantValue(provider.provide);
          });
        }
      }));
    }

    this.loaded = true;
  }

  async configure(config: ConfigService): Promise<void> {
    if (this.Crate.configure) {
      await this.Crate.configure(config);
    }
  }

  async init(container: Container): Promise<void> {
    const crate: ICrate = container.resolve(this.Crate);

    if (crate.onInit) {
      await crate.onInit();
    }
  }

  isLoaded(): boolean {
    return this.loaded;
  }
}

export class LazyDependency {
  private promise: Promise<ICrateConstructor<any>> | null = null

  constructor(private readonly loadFunc: any) {}

  public async load() {
    if (!this.promise) {
      this.promise = this.loadFunc();
    }

    return this.promise;
  }
}

export interface LazyCratesMap {
  [key: string]: LazyDependency
}

export type DependencyBucket = Dependency[];

/**
 * Responsible for loading crates in distinct way, so we
 * avoid loading the same Crate twice.
 */
export class DependencyLoader {
  private dependencies: IDependencyMap = {}

  constructor(
    private storeManager: StoreManager,
    private container: Container,
    private logger: Logger,
    private config: ConfigService,
    private panels: React.ComponentClass[],
    private providers: React.ComponentClass[],
    private lazyCrates: LazyCratesMap,
  ) { }

  /**
   * Recursively loads Crate with all his dependencies
   *
   * @returns {Array<DependencyBucket>} dependencies spitted into buckets
   */
  async load(Crate: ICrateConstructor<ICrateBaseSettings>): Promise<Array<DependencyBucket>> {
    this.logger.debug(`Starting loading ${Crate.settings.name}`);

    await this.loadDependenciesIntoMap(Crate);

    const buckets = this.splitDependenciesIntoBuckets();

    this.logger.debug(`Loading ${Object.values(this.dependencies).filter(dep => !dep.isLoaded()).length} crates in ${buckets.length} buckets`);

    // Load all services and reducers
    for (let i = 0; i < buckets.length; i++) {
      await Promise.all(buckets[i].map(dep => dep.load(
        this.storeManager,
        this.container,
        this.logger,
        this.config,
        this.panels,
        this.providers,
      )));
    }

    this.storeManager.refreshStore();

    this.logger.debug('Crate initialization finished');

    return buckets;
  }

  /**
   * Prepare config service with all necessary data
   *
   * @param buckets prepared by load method buckets of dependency
   */
  async configure(buckets: Array<DependencyBucket>): Promise<void> {
    // Run init life cycle method for each dependency
    for (let i = 0; i < buckets.length; i++) {
      await Promise.all(buckets[i].map(dep => dep.configure(this.config)));
    }
  }

  /**
   * Run initialization phase recursively on provided buckets
   *
   * @param buckets prepared by load method buckets of dependency
   */
  async init(buckets: Array<DependencyBucket>): Promise<void> {
    // Run init life cycle method for each dependency
    for (let i = 0; i < buckets.length; i++) {
      await Promise.all(buckets[i].map(dep => dep.init(this.container)));
    }
  }

  /**
   * Recursively load crates into unique one level map to optimize
   * further processing
   */
  private async loadDependenciesIntoMap(
    Crate: ICrateConstructor<ICrateBaseSettings>,
  ): Promise<void> {
    // Avoid loading the same crate again
    if (this.dependencies[Crate.settings.name]) return;

    // Load crate into map
    this.dependencies[Crate.settings.name] = new Dependency(Crate);

    // Load crates from imports if exists
    if (Crate.settings.imports) {
      const lazyCrates = await Promise.all(
        (Crate.settings.imports
          .filter((
            imp: LazyCrate | ICrateConstructor<ICrateBaseSettings>,
          ) => imp instanceof LazyCrate && imp.toPreload()) as LazyCrate[])
          .map<Promise<ICrateConstructor<any>>>((imp: LazyCrate) => imp.load(this.config)),
      );

      await Promise.all(Crate.settings.imports.map(async (
        imp: LazyCrate | ICrateConstructor<ICrateBaseSettings>,
      ) => {
        if (imp instanceof LazyCrate) {
          if (imp.toPreload()) {
            await this.loadDependenciesIntoMap(
              lazyCrates.find(crate => crate.settings.name === imp.crateName) as any,
            );
          } else if (!Object.keys(this.lazyCrates).includes(imp.crateName)) {
            this.lazyCrates[imp.crateName] = new LazyDependency(async () => {
              const LoadedCrate = await imp.load(this.config);

              const buckets = await this.load(LoadedCrate);

              await this.configure(buckets);
              await this.init(buckets);
            });
          }
        } else {
          await this.loadDependenciesIntoMap(imp);
        }
      }));
    }
  }

  /**
   * To optimize loading of multi level dependency system of crates
   * loading is split into Promise.all chain, to avoid loading all
   * dependencies synchronously.
   *
   * In each cycle of recursion push bucket into provided list and filter
   * them until no dependency is available.
   *
   * @param {Array<DependencyBucket>} buckets Collection of buckets initially empty
   * @param {Dependency[]} deps Collection of available dependencies,
   * initially list of all unloaded dependencies
   *
   * @returns {Array<DependencyBucket>} Collection of buckets after processing
   */
  private splitDependenciesIntoBuckets(
    buckets: Array<DependencyBucket> = [],
    deps: Dependency[] = Object.values(this.dependencies).filter(dep => !dep.isLoaded()),
  ): Array<DependencyBucket> {
    // Finish if no dependency is available. Break recursion
    if (deps.length === 0) return buckets;

    const [bucket, available] = this.extractBucketFromDependencies(deps);

    buckets.push(bucket);

    return this.splitDependenciesIntoBuckets(buckets, available);
  }

  /**
   * Leaf's of dependency tree goes into bucket the rest need to be store for next cycle.
   * When available dependencies will be empty we finish extraction
   */
  private extractBucketFromDependencies(deps: Dependency[]): [DependencyBucket, Dependency[]] {
    return deps.reduce<[DependencyBucket, Dependency[]]>((acc, dep) => {
      // Check if Crate has unloaded dependencies
      if (this.crateHasUnloadedDependencies(dep.Crate, deps)) {
        // We need to wait until dependencies will be loaded, so we leave it for later
        acc[1].push(dep);
      } else {
        // Dependency will be loaded in next bucket
        acc[0].push(dep);
      }

      return acc;
    }, [[], []]);
  }

  /**
   * In tree of dependency we need to detect if dependency is the leaf or is node in tree.
   * Nodes couldn't be resolved, because they could depend on services
   * that will be loaded after his dependencies will be fully loaded
   */
  private crateHasUnloadedDependencies(
    Crate: ICrateConstructor<ICrateBaseSettings>,
    deps: Dependency[],
  ): boolean {
    return Crate.settings.imports !== undefined && (
      Crate.settings.imports
        .filter((imp: LazyCrate | ICrateConstructor<ICrateBaseSettings>) => (
          deps.map(val => val.Crate.settings.name).includes(
            imp instanceof LazyCrate ? imp.crateName : imp.settings.name,
          )
        )).length > 0
    );
  }
}
