import {
  Button,
  AutoSavedFilteredSelectControl,
  AutoSavedTagsInputControl,
  CheckboxControl,
  TextFieldControl,
  SelectControl,
  SwitcherControl,
  AutoSavedSelectControl,
  AutoSavedSwitcherControl,
  AutoSavedTextFieldControl,
  AutoSavedCheckboxControl,
  autoSaved,
} from '@piwikpro/ui-components';
import { action } from './components/Secured';

export { default as errorBoundary } from './components/ErrorBoundary';
export { default as CopyToClipboard } from './components/CopyToClipboard';
export { env } from './container';
export { Pagination } from './components/Pagination';
export * from './config/forms';
export {
  action,
  view,
  withAccess,
  AccessContext,
} from './components/Secured';

const DelayedAutoSavedNumberControl = autoSaved(
  { saveOnBlur: true, debounceWait: 20 },
)(TextFieldControl);

export const Action = {
  DelayedAutoSavedNumberControl: action({ role: 'USER', level: 'edit' })(DelayedAutoSavedNumberControl),
  Button: action({ role: 'USER', level: 'edit' })(Button),
  AutoSavedTagsInputControl: action({ role: 'USER', level: 'edit' })(AutoSavedTagsInputControl),
  CheckboxControl: action({ role: 'USER', level: 'edit' })(CheckboxControl),
  TextControl: action({ role: 'USER', level: 'edit' })(TextFieldControl),
  PasswordControl: action({ role: 'USER', level: 'edit' })(TextFieldControl),
  NumberControl: action({ role: 'USER', level: 'edit' })(TextFieldControl),
  AutoSavedNumberControl: action({ role: 'USER', level: 'edit' })(AutoSavedTextFieldControl),
  SelectControl: action({ role: 'USER', level: 'edit' })(SelectControl),
  AutoSavedSelectControl: action({ role: 'USER', level: 'edit' })(AutoSavedSelectControl),
  AutoSavedFilteredSelectControl: action({ role: 'USER', level: 'edit' })(AutoSavedFilteredSelectControl),
  EmailControl: action({ role: 'USER', level: 'edit' })(TextFieldControl),
  SwitcherControl: action({ role: 'USER', level: 'edit' })(SwitcherControl),
  AutoSavedSwitcherControl: action({ role: 'USER', level: 'edit' })(AutoSavedSwitcherControl),
  AutoSavedCheckboxControl: action({ role: 'USER', level: 'edit' })(AutoSavedCheckboxControl),
};

export {
  Platform,
  decorate,
  inject,
  injectable,
  multiInject,
  ConfigService,
  withInject,
  Crate,
  Logger,
  Service,
  lazy,
  useService,
} from './Platform';
