import { Service, inject } from '@piwikpro/platform';
import { createAction } from 'redux-actions';
import type { Store } from 'redux';
import { TitlePart } from './TitleProvider';

export interface TitleTemplate {
  (parts: TitlePart[]): string
}

export interface TitleChanged {
  parts?: TitlePart[]
  title: string
}

export const titleChanged = createAction<TitleChanged, TitleChanged>(
  '@system/event/title/change',
  payload => payload,
);

@Service()
export class TitleService {
  private template: TitleTemplate = (parts: TitlePart[]) => {
    let separator = '';
    const hasImportant = parts.map(part => part.important).reduce((acc, val) => acc || val, false);

    return parts.sort((a, b) => {
      if ((a.important && b.important) || !(a.important || b.important)) return 0;

      if (a.important && !b.important) return 1;

      return -1;
    })
      .slice(-5)
      .reverse()
      .reduce((acc, part) => {
      // eslint-disable-next-line no-param-reassign
        acc += (part.subset && hasImportant ? ` (${part.value})` : separator + part.value);

        separator = ' - ';

        return acc;
      }, '');
  }

  constructor(
    @inject('document') private readonly doc: Document,
    @inject('store') private readonly store: Store,
  ) {}

  get(): string {
    return this.doc.title;
  }

  update(parts: TitlePart[]): void {
    const title = this.template(parts);

    this.store.dispatch(titleChanged({ parts, title }));

    this.doc.title = title;
  }

  subset(part?: TitlePart): void {
    const title = this.template([...this.store.getState().title.parts, part].filter(p => p));

    this.store.dispatch(titleChanged({ title }));

    this.doc.title = title;
  }

  setTemplate(template: TitleTemplate): void {
    this.template = template;
  }
}
