import { interfaces } from 'inversify';
import { PreferenceStorage, ListPreference } from '@piwikpro/user-preferences';
import { ConfigService } from '../Platform';

export default ({ container }: interfaces.Context) => {
  const config: ConfigService = container.get('config');

  ListPreference.changeDefault('limit', config.get('PAGINATION_ITEMS_PER_PAGE'));

  return new PreferenceStorage(
    container.get('localStorage'),
    'pp-user-preferences',
  );
};
