export default class AccessManager {
  constructor(firewallPasses = []) {
    this.firewallPasses = firewallPasses;
  }

  create(firewallPass) {
    return new AccessManager(this.firewallPasses.concat([firewallPass]));
  }

  isGranted(extraContext = {}) {
    if (this.firewallPasses.length === 0) return true;

    const passes = this.firewallPasses[Symbol.iterator]();

    const next = () => {
      const pass = passes.next();

      if (pass.done) {
        return true;
      }

      return pass.value.isAllowed(extraContext, next);
    };

    return passes.next().value.isAllowed(extraContext, next);
  }
}
