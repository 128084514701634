import { Service } from '@piwikpro/platform';

@Service()
export class ServiceUnavailableError extends Error {
  name = 'ServiceUnavailableError';

  headers = null;

  errors = {};

  constructor(headers: any) {
    super();

    this.headers = headers;
  }
}
