import * as Yup from 'yup';
import { regExps } from './regExps';

export default (yup: typeof Yup, t: Function) => ({
  ips: yup.array().of(
    yup.string().matches(regExps.ip4And6, t('administration:auditLog.validation.errors.filters.ips')),
  ),
  createdAt: yup.object().shape({
    from: yup.mixed(),
    // ts can't handle below custom validator
    // @ts-ignore:next-line
    to: yup.mixed().dateTimeGreaterThen(yup.ref('from'), t('administration:auditLog.validation.errors.filters.createdAt')),
  }),
  subjectIds: yup.array().of(
    yup.string().uuid(t('administration:auditLog.validation.errors.filters.subjectIds')),
  ),
});
