import * as Yup from 'yup';

export default (yup: typeof Yup, t: any) => ({
  scrollThresholds: yup.array(
    yup.number()
      .min(1, t('container-settings:automaticScrollTracking.validation.min', { count: 1 }))
      .max(100, t('container-settings:automaticScrollTracking.validation.max', { count: 100 }))
      .integer(t('container-settings:automaticScrollTracking.validation.integer'))
      // equivalent of required() - for this specific case:
      // yup.array() automatically casts values on given type (number)
      // TextField (for number prop type) returns empty string
      // which throws yup type error instead of required() validation rule
      .typeError(t('container-settings:automaticScrollTracking.validation.required')),
  // ts can't handle below custom validator
  // @ts-ignore:next-line
  ).uniqueArray({
    fieldName: 'scrollThresholds',
    message: t('container-settings:automaticScrollTracking.validation.uniqueArray'),
  }),
});
