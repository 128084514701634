/* eslint-disable no-underscore-dangle */
export default class Preference {
  constructor(id, values, scopeName, scopeDefaults) {
    this.id = id;
    this.value = values[this.id] || {};
    this.scopeName = scopeName;
    this.scopeDefaults = scopeDefaults;
  }

  addIntoScope(name, value) {
    if (this.value.__scopes === undefined) {
      this.value.__scopes = {};
    }
    if (this.value.__scopes[this.scopeName] === undefined) {
      this.value.__scopes[this.scopeName] = {};
    }

    this.value.__scopes[this.scopeName][name] = value;
  }

  getFromScope(name) {
    let result;

    if (this.value.__scopes && this.value.__scopes[this.scopeName]) {
      result = this.value.__scopes[this.scopeName][name];
    }

    return result;
  }

  toRawObject() {
    return this.value;
  }
}
/* eslint-enable no-underscore-dangle */
