import { ConfigBuilder } from '@piwikpro/ui-components';

export function i18nFactory({ getService }: any) {
  const builder = getService('TranslationCrate.i18nConfigBuilder');

  return builder.i18n;
}

export function i18nConfigFactory() {
  return new ConfigBuilder();
}
