import { Service } from '@piwikpro/platform';
import { getJwtPayload } from '@piwikpro/ui-components';
import moment, { Moment } from 'moment';

const JWT_REFRESH_WINDOW = 60; // in seconds
const JWT_TIME_MULTIPLIER = 1000;

@Service()
export class JWTTimer {
  private startTime: Moment = moment();

  private jwtTtl = 0;

  setJwtTtl(jwt: string): void {
    const jwtPayload = getJwtPayload(jwt);
    const startTime = moment(jwtPayload.iat * JWT_TIME_MULTIPLIER);
    const endTime = moment(jwtPayload.exp * JWT_TIME_MULTIPLIER);

    this.jwtTtl = this.getDurationInSeconds(startTime, endTime);
  }

  getJwtTtl(): number {
    return this.jwtTtl;
  }

  startTimer(currentTime = moment()): void {
    this.startTime = currentTime;
  }

  getDurationInSeconds(startTime: Moment, endTime: Moment = moment()): number {
    const timerDiff = moment.duration(endTime.diff(startTime));

    return Number(Math.round(timerDiff.asSeconds()));
  }

  hasJwtExpired(): boolean {
    if (this.jwtTtl) {
      return (this.jwtTtl - this.getDurationInSeconds(this.startTime)) < JWT_REFRESH_WINDOW;
    }

    return false;
  }
}
