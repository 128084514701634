/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import HttpCrate from '@piwikpro/http-crate';
import NotificationCrate from '@piwikpro/notification-crate';
import { LicenseKeeper } from './LicenseKeeper';
import { LicenseLimitsService } from './LicenseLimitsService';
import LicenseKeeperPanel from './LicenseKeeperPanel';

import reducer from './reducer';

@Crate({
  name: 'LicenseKeeperCrate',
  imports: [
    HttpCrate,
    NotificationCrate,
  ],
  services: [
    { name: 'license', provide: LicenseKeeper },
    { name: 'licenseLimits', provide: LicenseLimitsService },
  ],
  reducers: {
    licenseKeeper: reducer,
  },
  panels: [LicenseKeeperPanel],
})
export class LicenseKeeperCrate {}
