import { Service } from '@piwikpro/platform';

@Service()
export class ForbiddenError extends Error {
  name = 'ForbiddenError';

  constructor(name: string, public readonly errors?: Array<any> | any) {
    super(name);
  }
}
