import React from 'react';
import classNameBind from 'classnames/bind';
import { Image, Logo } from '@piwikpro/ui-components';
import styles from './LogoAndFaviconPreview.module.css';

const bindClasses = classNameBind.bind(styles);

interface LogoAndFaviconPreviewProps {
  instanceAddress?: string
  moduleName: string
  logo: string
  favicon: string
}

export const LogoAndFaviconPreview = ({
  instanceAddress = document.location.origin,
  moduleName,
  logo,
  favicon,
}: LogoAndFaviconPreviewProps) => (
  <div className={bindClasses('background')}>
    <div data-ppuc-id="favicon-preview" className={bindClasses('favicon')}>
      <Image src={favicon} />
    </div>
    <div className={bindClasses('instanceAddress')}>{instanceAddress}</div>
    <div data-ppuc-id="logo-preview" className={bindClasses('productBar')}>
      <Logo logoSrc={logo} logoLabel={moduleName} />
    </div>
  </div>
);
