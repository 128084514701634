/* eslint-disable camelcase */
import { handleActions, createAction } from 'redux-actions';

export interface TwoFactorAuthSetupData {
  totp_uri: string
  backup_codes: string[]
}

export interface UserAuthSettingsState {
  twoFactorAuth: {
    enforced: boolean
    enabled: boolean
  }
  saml: {
    enabled: boolean
  }
  isLoading: boolean
  failed: boolean
  setup: {
    isLoading: boolean
    failed: boolean
    data: null | TwoFactorAuthSetupData
    failReason: null | string
    completed: boolean
  }
}

const initialState: UserAuthSettingsState = {
  twoFactorAuth: {
    enforced: false,
    enabled: false,
  },
  saml: {
    enabled: false,
  },
  isLoading: true,
  failed: false,
  setup: {
    isLoading: true,
    failed: false,
    failReason: null,
    data: null,
    completed: false,
  },
};

export const getAuthSettingsForUserInitialized = createAction(
  '@platform/query/auth/settingsForUser/get/init',
);
export const getAuthSettingsForUserSucceeded = createAction(
  '@platform/query/auth/settingsForUser/get/success',
  (payload: any) => payload,
);
export const getAuthSettingsForUserFailed = createAction(
  '@platform/query/auth/settingsForUser/get/failed',
);
export const updateAuthSettingsForUserSucceeded = createAction(
  '@platform/command/auth/settingsForUser/update/success',
);
export const updateAuthSettingsForUserUpdateFailed = createAction(
  '@platform/command/auth/settingsForUser/update/failed',
);
export const get2faSetupForUserInitialized = createAction(
  '@platform/query/auth/2faSetupForUser/get/init',
);
export const get2faSetupForUserSucceeded = createAction(
  '@platform/query/auth/2faSetupForUser/get/success',
);
export const get2faSetupForUserFailed = createAction(
  '@platform/query/auth/2faSetupForUser/get/failed',
  (payload: any) => payload,
);
export const set2faSetupForUserSucceeded = createAction(
  '@platform/command/auth/2faSetupForUser/update/success',
);
export const set2faSetupForUserFailed = createAction(
  '@platform/command/auth/2faSetupForUser/update/failed',
);
export const delete2faSetupForUserSucceeded = createAction(
  '@platform/command/auth/2faSetupForUser/delete/success',
);
export const delete2faSetupForUserFailed = createAction(
  '@platform/command/auth/2faSetupForUser/delete/failed',
);
export const reset2faForSpecificUserInitialized = createAction(
  '@platform/command/auth/2faResetForUser/init',
);
export const reset2faForSpecificUserSucceeded = createAction(
  '@platform/command/auth/2faResetForUser/success',
);
export const reset2faForSpecificUserFailed = createAction(
  '@platform/command/auth/2faResetForUser/failed',
);
// actions names are equal to legacy ones from Users crate
export const passwordChangeInitialized = createAction(
  '@platform/command/me/changePassword/init',
);
export const passwordChangeSucceeded = createAction(
  '@platform/command/me/changePassword/success',
);
export const passwordChangeFailed = createAction(
  '@platform/command/me/changePassword/failed',
);

export default handleActions<UserAuthSettingsState, any>({
  [getAuthSettingsForUserInitialized as any]: state => ({
    ...state,
    isLoading: true,
    failed: false,
    setup: initialState.setup,
  }),
  [getAuthSettingsForUserSucceeded as any]: (state, { payload }: any) => ({
    ...state,
    isLoading: false,
    failed: false,
    twoFactorAuth: {
      enforced: payload['2fa'].enforced,
      enabled: payload['2fa'].enabled,
    },
    saml: {
      enabled: payload.saml?.enabled || false,
    },
  }),
  [getAuthSettingsForUserFailed as any]: state => ({
    ...state,
    isLoading: false,
    failed: true,
  }),
  [get2faSetupForUserInitialized as any]: state => ({
    ...state,
    setup: initialState.setup,
  }),
  [get2faSetupForUserSucceeded as any]: (state, { payload }) => ({
    ...state,
    setup: {
      ...state.setup,
      isLoading: false,
      failed: false,
      data: payload,
      completed: false,
    },
  }),
  [get2faSetupForUserFailed as any]: (state, { payload }) => ({
    ...state,
    setup: {
      ...state.setup,
      isLoading: false,
      failed: true,
      failReason: payload,
    },
  }),
  [set2faSetupForUserSucceeded as any]: state => ({
    ...state,
    setup: {
      ...state.setup,
      isLoading: false,
      failed: false,
      completed: true,
    },
  }),
  [set2faSetupForUserFailed as any]: state => ({
    ...state,
    setup: {
      ...state.setup,
      isLoading: false,
      failed: true,
    },
  }),
}, initialState);
