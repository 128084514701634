import 'whatwg-fetch';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { query } from '@piwikpro/url';
import { Service, inject } from '@piwikpro/platform';
import {
  IRequest,
  Interceptable,
  NextFunction,
  Configurable,
} from './interfaces';
import { JsonApiInterceptor } from './httpInterceptors/JsonApiInterceptor';
import { JsonInterceptor } from './httpInterceptors/JsonInterceptor';
import { TextInterceptor } from './httpInterceptors/TextInterceptor';
import { FileInterceptor } from './httpInterceptors/FileInterceptor';

const wait = (delay: number): Promise<void> => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, delay);
});

export class RequestPipeline<Data> {
  constructor(
    private request: Required<IRequest<Data>>,
    private interceptors: Interceptable[],
    private readonly jsonApiInterceptor: JsonApiInterceptor,
    private readonly jsonInterceptor: JsonInterceptor,
    private readonly textInterceptor: TextInterceptor,
    private readonly fileInterceptor: FileInterceptor,
  ) {}

  intercept(interceptor: (() => Interceptable) | Interceptable) {
    this.interceptors.push(typeof interceptor === 'function' ? interceptor() : interceptor);

    return this;
  }

  json() {
    this.interceptors.push(this.jsonInterceptor);

    return this;
  }

  text() {
    this.interceptors.push(this.textInterceptor);

    return this;
  }

  file() {
    this.interceptors.push(this.fileInterceptor);

    return this;
  }

  jsonApi() {
    this.interceptors.push(this.jsonApiInterceptor);

    return this;
  }

  async send<T=Data>(): Promise<T> {
    const interceptors = this.interceptors[Symbol.iterator]();

    const next: NextFunction = async (request) => {
      const {
        endpoint,
        queryParams,
        dropEmptyQueryParams,
        method,
        headers,
        credentials,
        body,
        delay,
        retryNumber,
        retryInterval,
      } = request;

      const interceptor = interceptors.next();
      let retryCounter = retryNumber - 1;

      if (interceptor.done) {
        const makeRequest = async (): Promise<T> => {
          if (delay) {
            await wait(delay);
          }

          let response: any = await fetch(
            this.parseRequestQueryParams(endpoint, queryParams, dropEmptyQueryParams),
            {
              method,
              headers,
              credentials,
              body,
            },
          );

          if (!response.ok && retryCounter) {
            await wait(retryInterval);

            retryCounter--;
            response = await makeRequest();
          }

          return response as any;
        };

        return makeRequest();
      }

      return interceptor.value.intercept<T>(request, next);
    };

    return next(this.request);
  }

  private parseRequestQueryParams(
    url: string,
    queryData: any | undefined,
    dropEmptyQueryParams: boolean = false,
  ): string {
    if (queryData) {
      let filteredQueryData = queryData;
      if (dropEmptyQueryParams) {
        filteredQueryData = omitBy(queryData, value => (
          isNil(value)
          || value === ''
          || (value.length !== undefined && value.length === 0)
        ));
      }
      return `${url}?${query.create(filteredQueryData)}`;
    }

    return url;
  }
}

@Service()
export class HttpClient {
  private config: Configurable = {
    common: {
      headers: {},
      delay: 0,
      retryNumber: 1,
      retryInterval: 0,
    },
  };

  private interceptors: Array<Interceptable> = [];

  constructor(
    @inject('HttpCrate.interceptors.JsonApi') private readonly jsonApiInterceptor: JsonApiInterceptor,
    @inject('HttpCrate.interceptors.Json') private readonly jsonInterceptor: JsonInterceptor,
    @inject('HttpCrate.interceptors.Text') private readonly textInterceptor: TextInterceptor,
    @inject('HttpCrate.interceptors.File') private readonly fileInterceptor: FileInterceptor,
  ) {}

  setConfig(key: string, config: Partial<IRequest>) {
    this.config[key] = merge({}, this.config[key], config);
  }

  addInterceptors(interceptors: Array<Interceptable>): void {
    this.interceptors.push(...interceptors);
  }

  request<T>(req: IRequest<T>): RequestPipeline<T> {
    const finalRequest: Required<IRequest<T>> = merge<
    any,
    Partial<IRequest>,
    Partial<IRequest>,
    Partial<IRequest>
    >(
      {}, this.config.common, this.config[req.method], req,
    );

    return new RequestPipeline<T>(
      finalRequest,
      this.interceptors.slice(),
      this.jsonApiInterceptor,
      this.jsonInterceptor,
      this.textInterceptor,
      this.fileInterceptor,
    );
  }

  clone(): HttpClient {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  getCookieValue(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts: string[] = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return (parts.pop() || '').split(';').shift();
    }
    return undefined;
  }
}
