import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

const signOnUrlValidator = (value: string): value is string => {
  const mainPartOfUrl = document.location.host;

  if (!value) {
    return true;
  }

  if (value.includes(mainPartOfUrl)) {
    return false;
  }

  return true;
};

export default (yup: typeof Yup, t: TFunction) => ({
  signOnUrl: yup
    .string()
    .test('is-different-from-hostname', t('accountSecurity.saml-settings.sing-on-url.validation'), signOnUrlValidator)
    .required(),
  entityId: yup.string().required(),
  publicCertificate: yup.string().required(),
  inactivityTimeout: yup
    .number()
    .integer(t('platform.accountSettings.sessionLength.validation-integer.info'))
    .min(15, t('platform.accountSettings.sessionLength.validation-min.info', { value: 15 }))
    .max(480, t('platform.accountSettings.sessionLength.validation-max.info', { value: 480 }))
    .required(),
});
