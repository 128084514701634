/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import HttpCrate from '@piwikpro/http-crate';
import TranslationCrate from '@piwikpro/translation-crate';
import ConfirmationCrate from '@piwikpro/confirmation-crate';
import NotificationCrate from '@piwikpro/notification-crate';
import LicenseKeeperCrate from '@piwikpro/license-keeper-crate';
import RouterCrate from '@piwikpro/router-crate';
import TitleCrate from '@piwikpro/title-crate';
import FormCrate from '@piwikpro/form-crate';
import {
  Organization,
  organization,
} from './Organization';
import LogoAndFavicon from './components/LogoAndFavicon';

@Crate({
  name: 'OrganizationCrate',
  imports: [
    HttpCrate,
    TranslationCrate,
    NotificationCrate,
    LicenseKeeperCrate,
    ConfirmationCrate,
    RouterCrate,
    TitleCrate,
    FormCrate
  ],
  reducers: {
    organization,
  },
  services: [
    { name: 'organization', provide: Organization },
  ],
  components: [
    { name: 'LogoAndFavicon', provide: LogoAndFavicon },
  ],
})
export class OrganizationCrate {}
