import { Service, inject } from '@piwikpro/platform';
import { Notifications } from '../Notifications';

@Service()
export class NotifyAboutRemoval {
  constructor(
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  async intercept(req: any, next: any) {
    const id = this.notifications.push({
      text: 'components:notifications.removal.processing',
      type: 'pending',
      autoCloseSeconds: 0,
      onClose: req.onNotificationRemove,
    });

    try {
      const resp = await next(req);

      this.notifications.update(id, {
        text: 'components:notifications.removal.success',
        type: 'success',
        autoCloseSeconds: 5,
        onClose: req.onNotificationRemove,
      });

      return resp;
    } catch (err) {
      this.notifications.update(id, {
        text: 'components:notifications.removal.failed',
        type: 'error',
        autoCloseSeconds: 5,
        onClose: req.onNotificationRemove,
      });

      throw err;
    }
  }
}
