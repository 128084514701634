export type ENVIRONMENT = 'production' | 'development';
const ENVIRONMENT_PREFIX = /^(REACT_APP_|FF_)/i;

export class ConfigService {
  constructor(private env: any = {}) {
    this.env = Object.keys(this.env)
      .reduce((acc: any, key: string) => {
        acc[key.replace(ENVIRONMENT_PREFIX, '')] = this.env[key];

        return acc;
      }, {});
  }

  get<T = string>(key: string): T {
    return this.env[key];
  }

  set(key: string, value: any): void {
    this.env[key] = value;
  }

  getEnv(): ENVIRONMENT {
    return this.env.NODE_ENV;
  }

  async load(url: string): Promise<void> {
    const config = await fetch(url).then(resp => resp.json()).catch(() => ({}));

    this.env = {
      ...this.env,
      ...config,
    };
  }
}
