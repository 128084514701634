import { combineReducers } from 'redux';
import organization, { OrganizationAuthSettingsState } from './organization';
import user, { UserAuthSettingsState } from './user';

export interface IAuthSettingsState {
  user: UserAuthSettingsState
  organization: OrganizationAuthSettingsState
}

export default combineReducers({
  organization,
  user,
});

export * from './organization';
export * from './user';
