export default class PreferenceStorage {
  constructor(storage, storageKey) {
    this.storage = storage;
    this.storageKey = storageKey;
  }

  load() {
    const data = this.storage.getItem([this.namespace, this.storageKey].join('.'));

    return data === null ? {} : JSON.parse(data);
  }

  addNamespace(namespace) {
    this.namespace = namespace;
  }

  persist(preference) {
    const data = this.load();

    data[preference.id] = preference.toRawObject();

    this.storage.setItem([this.namespace, this.storageKey].join('.'), JSON.stringify(data));
  }
}
