import * as Yup from 'yup';
import { regExps } from './regExps';

export default (
  yup: typeof Yup,
  t: Function,
) => ({
  currentPassword: yup.string()
    .required(),
  newPassword: yup.string()
    .required()
    .matches(regExps.password, t('profile:password.should.contain.form.info')),
  newPasswordRepeated: yup.string()
    .required()
    .matches(regExps.password, t('profile:password.should.contain.form.info'))
    // ts can't handle below custom validator
    // @ts-ignore:next-line
    .equalTo(yup.ref('newPassword'), t('profile:password.must.match')),
  clientName: yup.string()
    .trim()
    .required()
    .min(3)
    .max(255),
});
