/* eslint-disable no-case-declarations */
import * as Yup from 'yup';

const withMultipleAsterisk = /\*\*/;
const withAsteriskAlongWords = /\w+\*|\*\w/;
const withSlashAfterDotOrTrailingDot = /\.\/|\.$/;
const withIncorrectPort = /:[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z-_]+/;
const urlRegexp = /^https?:\/\/((([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z0-9-_]|(\*\.))+\.?)(([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z0-9-_]|(\.\*\.))+\.?)*((\/?[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z0-9-_]|(\.\*\.))\/?)*)\??(.*)(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/;
const domain = /^(\*\.)?([\w-]+\.)+[\w]{2,}$/;
const mobileURIAndDeepLinkRegex = new RegExp(/^(?!https?|ftp)[a-zA-Z][a-zA-Z0-9.\-+]*:\/\/[^\s:/?#]+(?::[0-9]+)?(?:\/(?!\/)[^\s?#]*)*(?:\?.*)?(?:#.*)?$/, 'i');
const includesCharacters = (
  value: string,
  characters: string[],
): boolean => characters.some((character: string) => value.includes(character));
const getDomainFromUrl = (url: string): string => url.split('/')[2];

export default (yup: typeof Yup, t: any) => ({
  name: yup.string()
    .max(90)
    .required(),
  urls: yup.array().of(
    yup.string().test('wildcardUrl', t('administration:apps.validation.urls.url'), (value: any) => {
      const isUrl = urlRegexp.test(value)
      && !withMultipleAsterisk.test(value)
      && !withIncorrectPort.test(value)
      && !withAsteriskAlongWords.test(value)
      && !withSlashAfterDotOrTrailingDot.test(value)
      && !includesCharacters(getDomainFromUrl(value), ['_']);

      return mobileURIAndDeepLinkRegex.test(value) || isUrl;
    }),
  ).min(1, t('administration:apps.validation.urls.min')),
  timezone: yup.string().required(),
  currency: yup.string().required(),
  delay: yup.number()
    .min(0, t('administration:apps.validation.delay.min', { count: 0 }))
    .max(10000, t('administration:apps.validation.delay.max', { count: 10000 }))
    .integer(t('administration:apps.validation.delay.integer'))
    .required(),
  privacyCookieDomains: yup.array().of(
    yup.string().matches(domain, t('administration:apps.validation.cookieDomains')),
  ),
  privacyCookieExpirationPeriod: yup.number(),
});
