import React from 'react';
import { connect } from 'react-redux';
import { withInject } from '@piwikpro/platform';
import FirstSteps from './components/FirstSteps';
import { Guides } from './Guides';
import { IGuidesState } from './redux';

export interface IGuidesPanelProps extends IGuidesState {
  guidesService: Guides
}

export class GuidesPanel extends React.Component<IGuidesPanelProps> {
  constructor(props: IGuidesPanelProps) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(guideName: string) {
    const { guidesService } = this.props;

    guidesService.closeGuide(guideName);
  }

  render() {
    const { guides } = this.props;

    if (guides.includes('first-steps')) {
      return (
        <FirstSteps onClose={() => this.handleClose('first-steps')} />
      );
    }

    return null;
  }
}

export default connect(
  (state: any) => ({
    guides: state.guides.guides,
  }),
  null,
)(
  withInject({
    guidesService: 'GuidesCrate.guides',
  })(GuidesPanel),
);
