import { Service, inject } from '@piwikpro/platform';
import {
  Interceptable,
  IRequest,
  NextFunction,
  HTTP_METHODS,
} from '../interfaces';
import {
  InvalidRequestError,
  InternalServerError,
  NotFoundError,
  ForbiddenError,
  UnauthorizedRequestError,
  ConflictError,
  ServiceUnavailableError,
  TooManyRequestsError,
} from '../httpErrors';

interface ErrorOptions {
  method?: HTTP_METHODS
}

@Service()
export class JsonApiInterceptor implements Interceptable {
  constructor(
    @inject('TranslationCrate.i18n') private readonly i18n: any,
  ) {}

  async intercept<T=any>(req: Required<IRequest>, next: NextFunction): Promise<T | null> {
    let json: any;
    let parsedErrors: any;

    req.headers['Content-Type'] = 'application/vnd.api+json';
    req.body = JSON.stringify(req.body);

    const resp = await next(req);

    if (!resp.ok) {
      switch (resp.status) {
        case 400:
          json = await resp.json();

          parsedErrors = this.parseFormErrorsFromApi(
            json.errors,
            {
              method: req.method,
            },
          );
          throw new InvalidRequestError('Invalid Request', parsedErrors);
        case 401:
          throw new UnauthorizedRequestError();
        case 403:
          throw new ForbiddenError('ForbiddenError');
        case 404:
          throw new NotFoundError();
        case 429:
          throw new TooManyRequestsError();
        case 409:
          json = await resp.json();
          throw new ConflictError('Conflict', json.errors);
        case 500:
          throw new InternalServerError();
        case 503:
          throw new ServiceUnavailableError(resp.headers);
        default:
          throw new Error(resp.status);
      }
    } else if (resp.status === 204) {
      return null;
    }

    return resp.json();
  }

  private parseFormErrorsFromApi(errors: any, options: ErrorOptions): any {
    const errorsObject: any = {};
    let pointerName: string;

    switch (options.method) {
      case 'GET':
        pointerName = 'parameter';
        break;
      default:
        pointerName = 'pointer';
        break;
    }

    errors.forEach((error: any) => {
      if (error.source) {
        const { [pointerName]: errorPointer } = error.source;
        const field = errorPointer.split('/').pop();
        const message = error.code ? this.i18n.t(`platform:${error.code}`) : error.detail;

        errorsObject[field] = message;
      } else {
        errorsObject.code = error.code;
      }
    });
    return errorsObject;
  }
}
