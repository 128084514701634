import { Subject } from 'rxjs';
import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '@piwikpro/http-crate/interfaces';
import { Session } from '../Session';

@Service()
export class JWTRevalidator implements Interceptable {
  private isJwtTokenRefreshing = false;

  private observableNewJwtToken = new Subject<string>();

  constructor(
    @inject('AuthCrate.session') private readonly session: Session,
    @inject('AuthCrate.jwtTimer') private readonly jwtTimer: any,
    @inject('config') private readonly config: any,
  ) {}

  async intercept<T>(req: Required<IRequest<T>>, next: NextFunction): Promise<T> {
    let resp;

    try {
      if (this.jwtTimer.hasJwtExpired()) {
        this.jwtTimer.startTimer();
        this.isJwtTokenRefreshing = true;
        const { token: newToken } = await this.session.getSession();
        this.observableNewJwtToken.next(newToken);
        this.isJwtTokenRefreshing = false;
        this.jwtTimer.setJwtTtl(newToken);

        req.headers.Authorization = `Bearer ${newToken}`;
      }

      if (this.isJwtTokenRefreshing && req.endpoint !== `${this.config.get('AUTH_URL')}/session`) {
        const newToken = await new Promise(resolve =>
          this.observableNewJwtToken.subscribe((value) => {
            resolve(value);
          }));

        req.headers.Authorization = `Bearer ${newToken}`;
      }

      resp = await next(req);
    } catch (err) {
      throw err;
    }

    return resp;
  }
}
