import React from 'react';
import * as Sentry from '@sentry/browser';
import { Information } from '@piwikpro/ui-components';

export default WrappedComponent => (
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        hasError: false,
        eventId: null,
      };

      this.showSentryReport = this.showSentryReport.bind(this);
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }

    showSentryReport() {
      const { eventId } = this.state;

      Sentry.showReportDialog({ eventId });
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return (
          <div>
            {/* temporary solution, should be discussed during UI sync */}
            <Information
              dataId="Information"
              type="error"
            >
              Error occured during Javascript code execution.
              Contact your administration for further assistance.
            </Information>
          </div>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }
);
