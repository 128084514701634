/* eslint-disable no-empty */
import { Service, inject } from '@piwikpro/platform';
import { BroadcastChannelService, getJwtPayload } from '@piwikpro/ui-components';
import type { History } from 'history';
import { HttpClient } from '@piwikpro/http-crate';
import type { Store } from 'redux';
import {
  JWT,
  JWTPayload,
  UUID,
  LanguageTag,
} from './interfaces';
import {
  getSessionSucceeded, appInitializationFailed, getOwnDetailsSucceeded, getModulesSucceeded,
  getSessionInitialized,
} from './reducers/session';
import { JWTTimer } from './JWTTimer';

export interface SessionSyncEvent {
  data: {
    name: 'token-updated' | 'logout'
    payload?: {
      token: string
      sessionExpDate: string
    }
  }
}

@Service()
export class Session {
  constructor(
    @inject('HttpCrate.httpClient') private readonly httpClient: HttpClient,
    @inject('store') private readonly store: Store,
    @inject('config') private readonly config: any,
    @inject('location') private readonly location: Location,
    @inject('RouterCrate.history') private readonly history: History,
    @inject('userPreferences') private readonly userPreferences: any,
    @inject('TranslationCrate.i18n') private readonly i18n: any,
    @inject('AuthCrate.jwtTimer') private readonly jwtTimer: JWTTimer,
    @inject('AuthCrate.sessionSyncChannel') private sessionSyncChannel: BroadcastChannelService,
  ) {}

  async getSession(): Promise<any | Error> {
    this.store.dispatch(getSessionInitialized());
    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('AUTH_URL')}/session`,
        method: 'GET',
        withoutRedirect: true,
      }).json().send<any>();
      const jwtPayload: JWTPayload = getJwtPayload(resp.token);


      this.setJwtToken(resp.token);

      this.store.dispatch(getSessionSucceeded({
        ...resp,
        userId: jwtPayload.sub,
        email: jwtPayload.eml,
        logoutUrl: `${this.config.get('AUTH_URL')}/logout`,
      }));

      try {
        this.sessionSyncChannel.postMessage({
          name: 'token-updated',
          payload: {
            token: resp.token,
            sessionExpDate: resp.session_expiration,
          },
        } as SessionSyncEvent['data']);
      } catch {}

      return resp;
    } catch (err: any) {
      throw err;
    }
  }

  async get(): Promise<UUID | void> {
    try {
      const resp = await this.getSession();
      const userId: UUID = getJwtPayload(resp.token).sub;

      this.userPreferences.addNamespace(userId);

      const [{
        attributes: { language },
      }] = await Promise.all([
        this.getOwnDetails(),
        this.getAvailableModules(),
      ]);

      await this.changeLanguage(language);

      return userId;
    } catch (err: any) {
      if (err.name === 'UnauthorizedRequestError') {
        this.login();

        throw err;
      }

      this.store.dispatch(appInitializationFailed());

      throw err;
    }
  }

  login(): void {
    const { location } = this.history;

    if (['', '/'].includes(location.pathname)) {
      this.location.href = '/login';
    } else {
      const relayState = `?RelayState=${encodeURIComponent(location.pathname)}${location.search}`;

      this.location.href = `/login${relayState}`;
    }
  }

  logout({
    relayState,
    withSyncMsg,
  }: Partial<{
    relayState: string
    withSyncMsg: boolean
  }> = {
    relayState: '/',
    withSyncMsg: true,
  }): void {
    const { session } = this.store.getState();

    // to reset user confirmation, set by UnsavedChangesModal
    window.onbeforeunload = () => {};

    if (withSyncMsg) {
      try {
        this.sessionSyncChannel.postMessage({
          name: 'logout',
        } as SessionSyncEvent['data']);
        this.sessionSyncChannel.close();
      } catch {}
    }

    this.location.replace(`${session.logoutUrl}?RelayState=${relayState}`);
  }

  public setJwtToken(jwt: JWT) {
    this.jwtTimer.setJwtTtl(jwt);
    this.jwtTimer.startTimer();
    this.configureHttpClient(jwt);
  }

  private configureHttpClient(jwt: JWT): void {
    const csrfToken = this.httpClient.getCookieValue('csrftoken');

    this.httpClient.setConfig('common', {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/vnd.api+json',
        'X-CSRFToken': csrfToken || '',
      },
    });
    this.httpClient.setConfig('GET', {
      retryNumber: this.config.get('REQUEST_RETRY_NUMBER'),
      retryInterval: this.config.get('REQUEST_RETRY_INTERVAL'),
    });
  }

  private changeLanguage(newLanguage: LanguageTag): Promise<void> {
    return new Promise((resolve) => {
      this.i18n.changeLanguage(newLanguage, resolve);
    });
  }

  private async getOwnDetails() {
    const resp = await this.httpClient.request({
      endpoint: `${this.config.get('USERS_URL')}/me`,
      method: 'GET',
    }).jsonApi().send<any>();

    this.store.dispatch(getOwnDetailsSucceeded(resp.data));

    return resp.data;
  }

  private async getAvailableModules() {
    const resp = await this.httpClient.request({
      endpoint: this.config.get('MODULES_URL'),
      method: 'GET',
    }).jsonApi().send<any>();

    this.store.dispatch(getModulesSucceeded(resp.data));
  }
}
