import { connect } from 'react-redux';
import LogoAndFavicon from './LogoAndFavicon';

const mapStateToProps = (state: any) => ({
  isCoreLicenseType: state.licenseKeeper.license?.plan.type === 'core',
  isLoading: state.organization.branding.isLoading,
  failed: state.organization.branding.failed,
  isDefaultSetup: state.organization.branding.default,
});

export default connect(mapStateToProps, null)(LogoAndFavicon);
