import React from 'react';
import { WithTranslation } from 'react-i18next';
import {
  ModalView,
  Stack,
  Text,
  Button,
  withTranslation,
  Trans,
  ButtonAppearance,
} from '@piwikpro/ui-components';

export interface ConfirmProps {
  messages: Array<string>
  name?: string
  title?: string
  applyBtnText?: string
  applyBtnAppearance?: ButtonAppearance
  onSubmit?: (event: React.SyntheticEvent) => void
  onCancel?: () => void
  t: (key: string, variables?: any) => string
}

export class Confirm extends React.Component<ConfirmProps> {
  render() {
    const {
      messages,
      name,
      title,
      applyBtnText,
      applyBtnAppearance,
      onSubmit,
      onCancel,
      t,
    } : ConfirmProps = this.props;

    return (
      <ModalView
        title={t(title || '', { name })}
        actions={(
          <Stack spacing="narrow" horizontalAlignment="right" verticalAlignment="center">
            <Button
              type="button"
              text={t('components:cancel')}
              appearance="default"
              onClick={onCancel}
            />
            <Button
              type="submit"
              text={applyBtnText ? t(applyBtnText) : t('profile:deleteConfirm.modal.submit')}
              appearance={applyBtnAppearance || 'destructive'}
              onClick={onSubmit}
              focused
            />
          </Stack>
        )}
        onClose={onCancel}
      >
        <form onSubmit={onSubmit}>
          <Stack vertical spacing="narrow">
            {messages?.map((message: string) => (
              <Stack.Item key={message}>
                <Text>
                  <Trans
                    i18nKey={t(message, { name })}
                    components={[
                      <b>text</b>,
                      <ul>
                        <li>text</li>
                      </ul>,
                    ]}
                  />
                </Text>
              </Stack.Item>
            ))}
          </Stack>
        </form>
      </ModalView>
    );
  }
}

export default withTranslation(['profile', 'components'])(Confirm);
