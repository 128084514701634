export type GdprPeriod = {
  key: string
  count: number
  id: number
  value: number
}[];

export default [
  {
    key: 'apps.details.settings.privacy.gdpr.periods.days',
    count: 1,
    id: 86400,
    value: 86400,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.days',
    count: 7,
    id: 604800,
    value: 604800,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.days',
    count: 14,
    id: 1209600,
    value: 1209600,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.days',
    count: 30,
    id: 2592000,
    value: 2592000,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.months',
    count: 3,
    id: 7776000,
    value: 7776000,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.months',
    count: 6,
    id: 15552000,
    value: 15552000,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.months',
    count: 12,
    id: 31536000,
    value: 31536000,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.years',
    count: 2,
    id: 63072000,
    value: 63072000,
  },
  {
    key: 'apps.details.settings.privacy.gdpr.periods.years',
    count: 3,
    id: 94608000,
    value: 94608000,
  },
];
