export type Currency = {
  name: string
  id: string
  value: string
}[];

export default [
  {
    name: 'United States dollar (USD)',
    id: 'USD',
    value: 'USD',
  },
  {
    name: 'Euro (EUR)',
    id: 'EUR',
    value: 'EUR',
  },
  {
    name: 'Japanese yen (JPY)',
    id: 'JPY',
    value: 'JPY',
  },
  {
    name: 'British pound (GBP)',
    id: 'GBP',
    value: 'GBP',
  },
  {
    name: 'Swiss franc (CHF)',
    id: 'CHF',
    value: 'CHF',
  },
  {
    name: 'Afghan afghani (AFN)',
    id: 'AFN',
    value: 'AFN',
  },
  {
    name: 'Albanian lek (ALL)',
    id: 'ALL',
    value: 'ALL',
  },
  {
    name: 'Algerian dinar (DZD)',
    id: 'DZD',
    value: 'DZD',
  },
  {
    name: 'Angolan kwanza (AOA)',
    id: 'AOA',
    value: 'AOA',
  },
  {
    name: 'Argentine peso (ARS)',
    id: 'ARS',
    value: 'ARS',
  },
  {
    name: 'Armenian dram (AMD)',
    id: 'AMD',
    value: 'AMD',
  },
  {
    name: 'Aruban florin (AWG)',
    id: 'AWG',
    value: 'AWG',
  },
  {
    name: 'Australian dollar (AUD)',
    id: 'AUD',
    value: 'AUD',
  },
  {
    name: 'Azerbaijani manat (AZN)',
    id: 'AZN',
    value: 'AZN',
  },
  {
    name: 'Bahamian dollar (BSD)',
    id: 'BSD',
    value: 'BSD',
  },
  {
    name: 'Bahraini dinar (BHD)',
    id: 'BHD',
    value: 'BHD',
  },
  {
    name: 'Bangladeshi taka (BDT)',
    id: 'BDT',
    value: 'BDT',
  },
  {
    name: 'Barbadian dollar (BBD)',
    id: 'BBD',
    value: 'BBD',
  },
  {
    name: 'Belarusian ruble (BYR)',
    id: 'BYR',
    value: 'BYR',
  },
  {
    name: 'Belize dollar (BZD)',
    id: 'BZD',
    value: 'BZD',
  },
  {
    name: 'Bermudian dollar (BMD)',
    id: 'BMD',
    value: 'BMD',
  },
  {
    name: 'Bitcoin (BTC)',
    id: 'BTC',
    value: 'BTC',
  },
  {
    name: 'Bhutanese ngultrum (BTN)',
    id: 'BTN',
    value: 'BTN',
  },
  {
    name: 'Bolivian boliviano (BOB)',
    id: 'BOB',
    value: 'BOB',
  },
  {
    name: 'Bosnia Herzegovina mark (BAM)',
    id: 'BAM',
    value: 'BAM',
  },
  {
    name: 'Botswana pula (BWP)',
    id: 'BWP',
    value: 'BWP',
  },
  {
    name: 'Brazilian real (BRL)',
    id: 'BRL',
    value: 'BRL',
  },
  {
    name: 'Brunei dollar (BND)',
    id: 'BND',
    value: 'BND',
  },
  {
    name: 'Bulgarian lev (BGN)',
    id: 'BGN',
    value: 'BGN',
  },
  {
    name: 'Burundian franc (BIF)',
    id: 'BIF',
    value: 'BIF',
  },
  {
    name: 'Cambodian riel (KHR)',
    id: 'KHR',
    value: 'KHR',
  },
  {
    name: 'Canadian dollar (CAD)',
    id: 'CAD',
    value: 'CAD',
  },
  {
    name: 'Cape Verdean escudo (CVE)',
    id: 'CVE',
    value: 'CVE',
  },
  {
    name: 'Cayman Islands dollar (KYD)',
    id: 'KYD',
    value: 'KYD',
  },
  {
    name: 'Central African CFA franc (XAF)',
    id: 'XAF',
    value: 'XAF',
  },
  {
    name: 'Chilean peso (CLP)',
    id: 'CLP',
    value: 'CLP',
  },
  {
    name: 'Chinese yuan (CNY)',
    id: 'CNY',
    value: 'CNY',
  },
  {
    name: 'Colombian peso (COP)',
    id: 'COP',
    value: 'COP',
  },
  {
    name: 'Comorian franc (KMF)',
    id: 'KMF',
    value: 'KMF',
  },
  {
    name: 'Congolese franc (CDF)',
    id: 'CDF',
    value: 'CDF',
  },
  {
    name: 'Costa Rican colón (CRC)',
    id: 'CRC',
    value: 'CRC',
  },
  {
    name: 'Croatian kuna (HRK)',
    id: 'HRK',
    value: 'HRK',
  },
  {
    name: 'CFP franc (XPF)',
    id: 'XPF',
    value: 'XPF',
  },
  {
    name: 'Cuban convertible peso (CUC)',
    id: 'CUC',
    value: 'CUC',
  },
  {
    name: 'Cuban peso (CUP)',
    id: 'CUP',
    value: 'CUP',
  },
  {
    name: 'Curaçao and Sint Maarten guilder (CMG)',
    id: 'CMG',
    value: 'CMG',
  },
  {
    name: 'Czech koruna (CZK)',
    id: 'CZK',
    value: 'CZK',
  },
  {
    name: 'Danish krone (DKK)',
    id: 'DKK',
    value: 'DKK',
  },
  {
    name: 'Djiboutian franc (DJF)',
    id: 'DJF',
    value: 'DJF',
  },
  {
    name: 'Dominican peso (DOP)',
    id: 'DOP',
    value: 'DOP',
  },
  {
    name: 'East Caribbean dollar (XCD)',
    id: 'XCD',
    value: 'XCD',
  },
  {
    name: 'Egyptian pound (EGP)',
    id: 'EGP',
    value: 'EGP',
  },
  {
    name: 'Eritrean nakfa (ERN)',
    id: 'ERN',
    value: 'ERN',
  },
  {
    name: 'Ethiopian birr (ETB)',
    id: 'ETB',
    value: 'ETB',
  },
  {
    name: 'Falkland Islands pound (FKP)',
    id: 'FKP',
    value: 'FKP',
  },
  {
    name: 'Fijian dollar (FJD)',
    id: 'FJD',
    value: 'FJD',
  },
  {
    name: 'Gambian dalasi (GMD)',
    id: 'GMD',
    value: 'GMD',
  },
  {
    name: 'Georgian lari (GEL)',
    id: 'GEL',
    value: 'GEL',
  },
  {
    name: 'Ghanaian cedi (GHS)',
    id: 'GHS',
    value: 'GHS',
  },
  {
    name: 'Gibraltar pound (GIP)',
    id: 'GIP',
    value: 'GIP',
  },
  {
    name: 'Guatemalan quetzal (GTQ)',
    id: 'GTQ',
    value: 'GTQ',
  },
  {
    name: 'Guinean franc (GNF)',
    id: 'GNF',
    value: 'GNF',
  },
  {
    name: 'Guyanese dollar (GYD)',
    id: 'GYD',
    value: 'GYD',
  },
  {
    name: 'Haitian gourde (HTG)',
    id: 'HTG',
    value: 'HTG',
  },
  {
    name: 'Honduran lempira (HNL)',
    id: 'HNL',
    value: 'HNL',
  },
  {
    name: 'Hong Kong dollar (HKD)',
    id: 'HKD',
    value: 'HKD',
  },
  {
    name: 'Hungarian forint (HUF)',
    id: 'HUF',
    value: 'HUF',
  },
  {
    name: 'Icelandic króna (ISK)',
    id: 'ISK',
    value: 'ISK',
  },
  {
    name: 'Indian rupee (INR)',
    id: 'INR',
    value: 'INR',
  },
  {
    name: 'Indonesian rupiah (IDR)',
    id: 'IDR',
    value: 'IDR',
  },
  {
    name: 'Iranian rial (IRR)',
    id: 'IRR',
    value: 'IRR',
  },
  {
    name: 'Iraqi dinar (IQD)',
    id: 'IQD',
    value: 'IQD',
  },
  {
    name: 'Israeli new shekel (ILS)',
    id: 'ILS',
    value: 'ILS',
  },
  {
    name: 'Jamaican dollar (JMD)',
    id: 'JMD',
    value: 'JMD',
  },
  {
    name: 'Jordanian dinar (JOD)',
    id: 'JOD',
    value: 'JOD',
  },
  {
    name: 'Kazakhstani tenge (KZT)',
    id: 'KZT',
    value: 'KZT',
  },
  {
    name: 'Kenyan shilling (KES)',
    id: 'KES',
    value: 'KES',
  },
  {
    name: 'Kuwaiti dinar (KWD)',
    id: 'KWD',
    value: 'KWD',
  },
  {
    name: 'Kyrgyzstani som (KGS)',
    id: 'KGS',
    value: 'KGS',
  },
  {
    name: 'Lao kip (LAK)',
    id: 'LAK',
    value: 'LAK',
  },
  {
    name: 'Lebanese pound (LBP)',
    id: 'LBP',
    value: 'LBP',
  },
  {
    name: 'Lesotho loti (LSL)',
    id: 'LSL',
    value: 'LSL',
  },
  {
    name: 'Liberian dollar (LRD)',
    id: 'LRD',
    value: 'LRD',
  },
  {
    name: 'Libyan dinar (LYD)',
    id: 'LYD',
    value: 'LYD',
  },
  {
    name: 'Lithuanian litas (LTL)',
    id: 'LTL',
    value: 'LTL',
  },
  {
    name: 'Macanese pataca (MOP)',
    id: 'MOP',
    value: 'MOP',
  },
  {
    name: 'Macedonian denar (MKD)',
    id: 'MKD',
    value: 'MKD',
  },
  {
    name: 'Malagasy ariary (MGA)',
    id: 'MGA',
    value: 'MGA',
  },
  {
    name: 'Malawian kwacha (MWK)',
    id: 'MWK',
    value: 'MWK',
  },
  {
    name: 'Malaysian ringgit (MYR)',
    id: 'MYR',
    value: 'MYR',
  },
  {
    name: 'Maldivian rufiyaa (MVR)',
    id: 'MVR',
    value: 'MVR',
  },
  {
    name: 'Mauritanian ouguiya (MRO)',
    id: 'MRO',
    value: 'MRO',
  },
  {
    name: 'Mauritian rupee (MUR)',
    id: 'MUR',
    value: 'MUR',
  },
  {
    name: 'Mexican peso (MXN)',
    id: 'MXN',
    value: 'MXN',
  },
  {
    name: 'Moldovan leu (MDL)',
    id: 'MDL',
    value: 'MDL',
  },
  {
    name: 'Mongolian tögrög (MNT)',
    id: 'MNT',
    value: 'MNT',
  },
  {
    name: 'Moroccan dirham (MAD)',
    id: 'MAD',
    value: 'MAD',
  },
  {
    name: 'Mozambican metical (MZN)',
    id: 'MZN',
    value: 'MZN',
  },
  {
    name: 'Myanma kyat (MMK)',
    id: 'MMK',
    value: 'MMK',
  },
  {
    name: 'Namibian dollar (NAD)',
    id: 'NAD',
    value: 'NAD',
  },
  {
    name: 'Nepalese rupee (NPR)',
    id: 'NPR',
    value: 'NPR',
  },
  {
    name: 'Netherlands Antillean guilder (ANG)',
    id: 'ANG',
    value: 'ANG',
  },
  {
    name: 'New Taiwan dollar (TWD)',
    id: 'TWD',
    value: 'TWD',
  },
  {
    name: 'New Zealand dollar (NZD)',
    id: 'NZD',
    value: 'NZD',
  },
  {
    name: 'Nicaraguan córdoba (NIO)',
    id: 'NIO',
    value: 'NIO',
  },
  {
    name: 'Nigerian naira (NGN)',
    id: 'NGN',
    value: 'NGN',
  },
  {
    name: 'North Korean won (KPW)',
    id: 'KPW',
    value: 'KPW',
  },
  {
    name: 'Norwegian krone (NOK)',
    id: 'NOK',
    value: 'NOK',
  },
  {
    name: 'Omani rial (OMR)',
    id: 'OMR',
    value: 'OMR',
  },
  {
    name: 'Pakistani rupee (PKR)',
    id: 'PKR',
    value: 'PKR',
  },
  {
    name: 'Panamanian balboa (PAB)',
    id: 'PAB',
    value: 'PAB',
  },
  {
    name: 'Papua New Guinean kina (PGK)',
    id: 'PGK',
    value: 'PGK',
  },
  {
    name: 'Paraguayan guaraní (PYG)',
    id: 'PYG',
    value: 'PYG',
  },
  {
    name: 'Peruvian nuevo sol (PEN)',
    id: 'PEN',
    value: 'PEN',
  },
  {
    name: 'Philippine peso (PHP)',
    id: 'PHP',
    value: 'PHP',
  },
  {
    name: 'Polish złoty (PLN)',
    id: 'PLN',
    value: 'PLN',
  },
  {
    name: 'Qatari riyal (QAR)',
    id: 'QAR',
    value: 'QAR',
  },
  {
    name: 'Romanian leu (RON)',
    id: 'RON',
    value: 'RON',
  },
  {
    name: 'Russian ruble (RUB)',
    id: 'RUB',
    value: 'RUB',
  },
  {
    name: 'Rwandan franc (RWF)',
    id: 'RWF',
    value: 'RWF',
  },
  {
    name: 'Saint Helena pound (SHP)',
    id: 'SHP',
    value: 'SHP',
  },
  {
    name: 'Salvadoran colón (SVC)',
    id: 'SVC',
    value: 'SVC',
  },
  {
    name: 'Samoan tala (WST)',
    id: 'WST',
    value: 'WST',
  },
  {
    name: 'São Tomé and Príncipe dobra (STD)',
    id: 'STD',
    value: 'STD',
  },
  {
    name: 'Saudi riyal (SAR)',
    id: 'SAR',
    value: 'SAR',
  },
  {
    name: 'Serbian dinar (RSD)',
    id: 'RSD',
    value: 'RSD',
  },
  {
    name: 'Seychellois rupee (SCR)',
    id: 'SCR',
    value: 'SCR',
  },
  {
    name: 'Sierra Leonean leone (SLL)',
    id: 'SLL',
    value: 'SLL',
  },
  {
    name: 'Singapore dollar (SGD)',
    id: 'SGD',
    value: 'SGD',
  },
  {
    name: 'Solomon Islands dollar (SBD)',
    id: 'SBD',
    value: 'SBD',
  },
  {
    name: 'Somali shilling (SOS)',
    id: 'SOS',
    value: 'SOS',
  },
  {
    name: 'South African rand (ZAR)',
    id: 'ZAR',
    value: 'ZAR',
  },
  {
    name: 'South Korean won (KRW)',
    id: 'KRW',
    value: 'KRW',
  },
  {
    name: 'Sri Lankan rupee (LKR)',
    id: 'LKR',
    value: 'LKR',
  },
  {
    name: 'Sudanese pound (SDG)',
    id: 'SDG',
    value: 'SDG',
  },
  {
    name: 'Surinamese dollar (SRD)',
    id: 'SRD',
    value: 'SRD',
  },
  {
    name: 'Swazi lilangeni (SZL)',
    id: 'SZL',
    value: 'SZL',
  },
  {
    name: 'Swedish krona (SEK)',
    id: 'SEK',
    value: 'SEK',
  },
  {
    name: 'Syrian pound (SYP)',
    id: 'SYP',
    value: 'SYP',
  },
  {
    name: 'Tajikistani somoni (TJS)',
    id: 'TJS',
    value: 'TJS',
  },
  {
    name: 'Tanzanian shilling (TZS)',
    id: 'TZS',
    value: 'TZS',
  },
  {
    name: 'Thai baht (THB)',
    id: 'THB',
    value: 'THB',
  },
  {
    name: 'Tongan paʻanga (TOP)',
    id: 'TOP',
    value: 'TOP',
  },
  {
    name: 'Trinidad and Tobago dollar (TTD)',
    id: 'TTD',
    value: 'TTD',
  },
  {
    name: 'Tunisian dinar (TND)',
    id: 'TND',
    value: 'TND',
  },
  {
    name: 'Turkish lira (TRY)',
    id: 'TRY',
    value: 'TRY',
  },
  {
    name: 'Turkmenistani manat (TMM)',
    id: 'TMM',
    value: 'TMM',
  },
  {
    name: 'Ugandan shilling (UGX)',
    id: 'UGX',
    value: 'UGX',
  },
  {
    name: 'Ukrainian hryvnia (UAH)',
    id: 'UAH',
    value: 'UAH',
  },
  {
    name: 'United Arab Emirates dirham (AED)',
    id: 'AED',
    value: 'AED',
  },
  {
    name: 'Uruguayan peso (UYU)',
    id: 'UYU',
    value: 'UYU',
  },
  {
    name: 'Uzbekistani som (UZS)',
    id: 'UZS',
    value: 'UZS',
  },
  {
    name: 'Vanuatu vatu (VUV)',
    id: 'VUV',
    value: 'VUV',
  },
  {
    name: 'Venezuelan bolívar (VEF)',
    id: 'VEF',
    value: 'VEF',
  },
  {
    name: 'Vietnamese đồng (VND)',
    id: 'VND',
    value: 'VND',
  },
  {
    name: 'West African CFA franc (XOF)',
    id: 'XOF',
    value: 'XOF',
  },
  {
    name: 'Yemeni rial (YER)',
    id: 'YER',
    value: 'YER',
  },
  {
    name: 'Zambian kwacha (ZMW)',
    id: 'ZMW',
    value: 'ZMW',
  },
  {
    name: 'Zimbabwean dollar (ZWL)',
    id: 'ZWL',
    value: 'ZWL',
  },
];
