import { handleActions, combineActions } from 'redux-actions';
import {
  confirmationInitialized,
  confirmationSucceeded,
  confirmationFailed,
} from '../Confirmation';

const initialState = {
  onCancel: null,
  onSubmit: null,
  messages: [],
  title: null,
  name: null,
  applyBtnText: null,
  applyBtnAppearance: null,
  type: '',
};

export default handleActions({
  [confirmationInitialized as any]: (state, { payload }: any) => ({
    ...state,
    ...payload,
  }),
  [combineActions(confirmationFailed, confirmationSucceeded) as any]: () => (initialState),
}, initialState);
