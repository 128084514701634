import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '../interfaces';
import {
  InvalidRequestError,
  InternalServerError,
  NotFoundError,
  ForbiddenError,
  UnauthorizedRequestError,
  ServiceUnavailableError,
} from '../httpErrors';

@Service()
export class TextInterceptor implements Interceptable {
  constructor(
    @inject('TranslationCrate.i18n') private readonly i18n: any,
  ) {}

  async intercept<T=any>(req: Required<IRequest>, next: NextFunction): Promise<T | null> {
    req.body = JSON.stringify(req.body);

    const resp = await next(req);

    if (!resp.ok) {
      switch (resp.status) {
        case 400:
          throw new InvalidRequestError('Invalid Request', []);
        case 404:
          throw new NotFoundError();
        case 403:
          throw new ForbiddenError('ForbiddenError');
        case 401:
          throw new UnauthorizedRequestError();
        case 500:
          throw new InternalServerError();
        case 503:
          throw new ServiceUnavailableError(resp.headers);
        default:
          throw new Error(resp.status);
      }
    } else if (resp.status === 204) {
      return null;
    }

    return resp.text();
  }
}
