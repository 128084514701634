import { Service, inject } from '@piwikpro/platform';
import { Notifications } from '../Notifications';

@Service()
export class NotifyOnFail {
  constructor(
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  async intercept(req: any, next: any) {
    try {
      return await next(req);
    } catch (err: any) {
      if (err.name === 'InvalidRequestError') {
        this.notifications.push({
          text: 'components:request.invalid.explanation',
          type: 'error',
          autoCloseSeconds: 5,
          onClose: req.onNotificationRemove,
        });
      }

      if (
        ['InternalServerError', 'ServiceUnavailableError'].includes(err.name)
        || Number(err.message) >= 500 // handles defaults from json/text interceptor
      ) {
        this.notifications.push({
          text: 'components:request.failed.explanation',
          type: 'error',
          autoCloseSeconds: 5,
          onClose: req.onNotificationRemove,
        });
      }

      throw err;
    }
  }
}
