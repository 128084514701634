/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import RouterCrate from '@piwikpro/router-crate';
import { Guides } from './Guides';
import GuidesPanel from './GuidesPanel';
import guides from './redux';

@Crate({
  name: 'GuidesCrate',
  imports: [
    RouterCrate,
  ],
  services: [
    { name: 'guides', provide: Guides },
  ],
  panels: [GuidesPanel],
  reducers: {
    guides,
    opa: {}
  },
})
export class GuidesCrate {}
