import * as Yup from 'yup';

const wildcard = /\*/g;
const ipRegexp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
const prefixedWildCardIp = /[^.:]\*|\*.*([^.:*]|::)/;
const withMultipleAsterisk = /\*\*/;
const withAsteriskAlongWords = /\w+\*|\*\w/;
const withSlashAfterDotOrTrailingDot = /\.\/|\.$/;
const withIncorrectPort = /:[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z-_]+/;
const urlRegexp = /^https?:\/\/((([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z0-9-_]|(\*\.))+\.?)(([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z0-9-_]|(\.\*\.))+\.?)*((\/?[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z0-9-_]|(\.\*\.))\/?)*)\??(.*)(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/;
const mobileURIAndDeepLinkRegex = new RegExp(/^(?!https?|ftp)[a-zA-Z][a-zA-Z0-9.\-+]*:\/\/[^\s:/?#]+(?::[0-9]+)?(?:\/(?!\/)[^\s?#]*)*(?:\?.*)?(?:#.*)?$/, 'i');

/* eslint-disable */
function inetPton (a: any) {
  // http://kevin.vanzonneveld.net
  // +   original by: Theriault
  // *     example 1: inet_pton('::');
  // *     returns 1: '\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0' (binary)
  // *     example 2: inet_pton('127.0.0.1');
  // *     returns 2: '\x7F\x00\x00\x01' (binary)
  var r, m, x, i, j, f = String.fromCharCode;
  m = a.match(/^(?:\d{1,3}(?:\.|$)){4}/); // IPv4
  if (m) {
      m = m[0].split('.');
      m = f(m[0]) + f(m[1]) + f(m[2]) + f(m[3]);
      // Return if 4 bytes, otherwise false.
      return m.length === 4 ? m : false;
  }
  r = /^((?:[\da-f]{1,4}(?::|)){0,8})(::)?((?:[\da-f]{1,4}(?::|)){0,8})$/;
  m = a.match(r); // IPv6
  if (m) {
      // Translate each hexadecimal value.
      for (j = 1; j < 4; j++) {
          // Indice 2 is :: and if no length, continue.
          if (j === 2 || m[j].length === 0) {
              continue;
          }
          m[j] = m[j].split(':');
          for (i = 0; i < m[j].length; i++) {
              m[j][i] = parseInt(m[j][i], 16);
              // Would be NaN if it was blank, return false.
              if (isNaN(m[j][i])) {
                  return false; // Invalid IP.
              }
              m[j][i] = f(m[j][i] >> 8) + f(m[j][i] & 0xFF);
          }
          m[j] = m[j].join('');
      }
      x = m[1].length + m[3].length;
      if (x === 16) {
          return m[1] + m[3];
      } else if (x < 16 && m[2].length > 0) {
          return m[1] + (new Array(16 - x + 1)).join('\x00') + m[3];
      }
  }
  return false; // Invalid IP.
}
/* eslint-enable */

export default (yup: typeof Yup, t: any) => ({
  urls: yup.array().of(
    yup.string().test('wildcardUrl', t('administration:apps.validation.urls.url'), (value: any) => {
      const isUrl = urlRegexp.test(value)
      && !withMultipleAsterisk.test(value)
      && !withIncorrectPort.test(value)
      && !withAsteriskAlongWords.test(value)
      && !withSlashAfterDotOrTrailingDot.test(value);

      return mobileURIAndDeepLinkRegex.test(value) || isUrl;
    }),
  ),
  campaignNameParams: yup.array().of(
    yup.string(),
  ),
  campaignKeywordParams: yup.array().of(
    yup.string(),
  ),
  campaignSourceParams: yup.array().of(
    yup.string(),
  ),
  campaignIdParams: yup.array().of(
    yup.string(),
  ),
  campaignContentParams: yup.array().of(
    yup.string(),
  ),
  campaignMediumParams: yup.array().of(
    yup.string(),
  ),
  siteSearchQueryParams: yup.array().of(
    yup.string(),
  ),
  siteSearchCategoryParams: yup.array().of(
    yup.string(),
  ),
  excludedIps: yup.array().of(
    yup.mixed().test(
      'ipV6',
      t('administration:apps.validation.excludedIps'),
      (value: string | any) => {
        // Tag as an injected object (having disabled property) -> validation skipped
        if (typeof value === 'object') {
          return true;
        }

        let ipRangeString = value.trim();
        let numWildcards;
        let bits;
        const slashPosition = ipRangeString.indexOf('/');

        if (ipRangeString === '') return false;

        // IP address with wildcards '*'
        if (ipRangeString.includes('*')) {
          // Disallow prefixed wildcards and anything other than wildcards
          // and separators (including IPv6 zero groups) after first wildcard
          if (prefixedWildCardIp.test(ipRangeString)) return false;

          numWildcards = (ipRangeString.match(wildcard) || []).length;
          ipRangeString = ipRangeString.replace(wildcard, '0');

        // CIDR
        } else if (slashPosition > -1) {
          bits = ipRangeString.substring(slashPosition + 1);
          ipRangeString = ipRangeString.substring(0, slashPosition);

          if (bits === '' || !Number.isInteger(Number(bits))) {
            return false;
          }
        }

        // single IP
        if (!ipRegexp.test(ipRangeString)) return false;

        const maxBits = inetPton(ipRangeString).length * 8;
        if (bits === undefined) {
          bits = maxBits;

          if (numWildcards !== undefined) {
            bits -= (maxBits === 32 ? 8 : 16) * numWildcards;
          }
        }

        if (bits < 0 || bits > maxBits) {
          return false;
        }

        return true;
      },
    ),
  ),
  excludedUrlParams: yup.array().of(
    yup.string().test('regExp', t('administration:errors.regexp.invalid'), (value: any) => {
      if (/\/(.*)\//.test(value)) {
        try {
          RegExp(value);
        } catch (err) {
          return false;
        }
      }

      return true;
    }),
  ),
  excludedUserAgents: yup.array().of(
    yup.string(),
  ),
  sessionMaxEventCount: yup.number()
    .label(t('apps.details.settings.session-limits.max-session-event-count.title'))
    .required()
    .positive()
    .integer()
    .min(1)
    .max(65535),
});
