import { connect } from 'react-redux';
import { withTranslation } from '@piwikpro/ui-components';
import FirstSteps from './FirstSteps';

export default withTranslation(['administration'])(
  connect(
    (state: any) => ({
      isAppsListLoading: state.administration
        ? state.administration.apps.list.isLoading
        : false,
      firstApp: (() => {
        const appsList = state.administration?.apps.list.apps;

        return appsList?.length && appsList[appsList.length - 1];
      })(),
    }),
    null,
  )(FirstSteps),
);
