import { Container } from 'inversify';

export interface ContainerStore {
  container: Container | null
}

export const containerStore: ContainerStore = {
  container: null,
};

export function useService<T = any>(serviceName: string): T {
  if (!containerStore.container) throw Error('You can\'t run "useService" without Platform');

  return containerStore.container.get<T>(serviceName);
}
