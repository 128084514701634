/* eslint-disable camelcase */
import { createAction, handleActions } from 'redux-actions';
import type { Store } from 'redux';
import md5 from 'js-md5';
import { Confirmation } from '@piwikpro/confirmation-crate';
import {
  NotifyOnFail,
  NotifyAboutUpdate,
} from '@piwikpro/notification-crate';
import {
  Service,
  inject,
} from '@piwikpro/platform';
import { HttpClient } from '@piwikpro/http-crate';

export const fetchOrganizationConfigSucceeded = createAction<Organization, Organization>(
  '@platform/query/organization/getConfig/success',
  (payload: Organization) => payload,
);
export const fetchOrganizationConfigFailed = createAction(
  '@platform/query/organization/getConfig/failed',
);
export const fetchOrganizationBrandingInitialized = createAction(
  '@platform/query/organization/getBranding/init',
);
export const fetchOrganizationBrandingSucceeded = createAction(
  '@platform/query/organization/getBranding/success',
  (payload: any) => payload,
);
export const fetchOrganizationBrandingFailed = createAction(
  '@platform/query/organization/getBranding/failed',
);
export const confirmReservationInit = createAction(
  '@platform/command/organization/confirmReservation/init',
);
export const confirmReservationSucceeded = createAction(
  '@platform/command/organization/confirmReservation/success',
  (payload: {
    set_password_code: string
  }) => payload,
);
export const confirmReservationFailed = createAction(
  '@platform/command/organization/confirmReservation/failed',
  (payload: string) => payload,
);

const generateKey = (...args: Array<string>): string => md5(JSON.stringify(args));

const generateUrl = (url: string, userId: string): string => {
  const hashFromUserId = generateKey(userId);

  return `${url}?cb=${hashFromUserId}`;
};

@Service()
export class Organization {
  constructor(
    @inject('store') private readonly store: Store,
    @inject('HttpCrate.httpClient') private readonly httpClient: HttpClient,
    @inject('NotificationCrate.interceptors.NotifyOnFail') private readonly notifyOnFailInterceptor: NotifyOnFail,
    @inject('NotificationCrate.interceptors.NotifyAboutUpdate') private readonly notifyAboutUpdate: NotifyAboutUpdate,
    @inject('ConfirmationCrate.confirmation') private readonly confirmation: Confirmation,
    @inject('config') private readonly config: any,
  ) {}

  async fetchConfig(userId: string) {
    try {
      const resp = await this.httpClient.request({
        endpoint: generateUrl(
          `${this.config.get('ORGANIZATION_URL')}/product-bar`,
          userId,
        ),
        method: 'GET',
      })
        .intercept(this.notifyOnFailInterceptor)
        .jsonApi()
        .send<any>();

      this.store.dispatch(
        fetchOrganizationConfigSucceeded(resp.data.attributes.apps),
      );

      return resp;
    } catch (err) {
      this.store.dispatch(fetchOrganizationConfigFailed());

      throw err;
    }
  }

  async checkReservation(reservationCode: string): Promise<{ set_password_code: string } | Error> {
    this.store.dispatch(confirmReservationInit());

    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('ORGANIZATION_URL')}/reservation/confirm`,
        method: 'POST',
        body: {
          reservation_code: reservationCode,
        },
      })
        .json()
        .send<any>();

      this.store.dispatch(confirmReservationSucceeded(resp));

      return resp;
    } catch (err: any) {
      this.store.dispatch(confirmReservationFailed(err));

      throw err;
    }
  }

  async fetchBranding() {
    this.store.dispatch(fetchOrganizationBrandingInitialized());

    try {
      const resp = await this.httpClient.request({
        endpoint: `${this.config.get('ORGANIZATION_URL')}/logo`,
        method: 'GET',
      })
        .intercept(this.notifyOnFailInterceptor)
        .json()
        .send();

      this.store.dispatch(fetchOrganizationBrandingSucceeded(resp));

      return resp;
    } catch (err) {
      this.store.dispatch(fetchOrganizationBrandingFailed());

      throw err;
    }
  }

  async resetBranding() {
    return this.confirmation.confirm({
      title: 'administration:accountSettings.logoAndFavicon.layout.settingsPreview.settings.restoreDefaultModal.title',
      messages: [
        'administration:accountSettings.logoAndFavicon.layout.settingsPreview.settings.restoreDefaultModal.message',
      ],
      applyBtnAppearance: 'primary',
      applyBtnText: 'administration:accountSettings.logoAndFavicon.layout.settingsPreview.settings.restoreDefaultModal.confirm',
    }).then(async () => {
      await this.httpClient.request({
        endpoint: `${this.config.get('ORGANIZATION_URL')}/logo`,
        method: 'DELETE',
      })
        .intercept(this.notifyAboutUpdate)
        .json()
        .send();
    });
  }

  async sendBrandingImages({ logo, favicon }: { logo: File, favicon: File }) {
    const formData = new FormData();

    formData.append('logo', logo);
    formData.append('favicon', favicon);

    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('ORGANIZATION_URL')}/logo`,
        method: 'POST',
        body: formData,
      })
        .intercept(this.notifyAboutUpdate)
        .file()
        .send();
    } catch (err: any) {
      throw err;
    }
  }

  async deleteCurrentOrganization({
    reason,
    description,
  }: {
    reason: string
    description: string
  }) {
    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('ORGANIZATION_URL')}/current`,
        method: 'DELETE',
        body: {
          reason: `${reason}|${description || 'No detailed description provided by user.'}`,
        },
      })
        .intercept(this.notifyOnFailInterceptor)
        .json()
        .send();
    } catch (err) {
      throw err;
    }
  }
}

type PpasModulesIds = 'analytics' | 'customer_data_platform' | 'tag_manager' | 'consent_manager';
type PpasModulesHrefs = '/analytics' | '/cdp' | '/tag' | '/consent';

interface PpasModule {
  id: PpasModulesIds
  href: PpasModulesHrefs
}

export interface OrganizationState {
  availableProducts: PpasModule[] | []
  branding: {
    isLoading: boolean
    failed: boolean
    default: boolean
  }
  isLoading: boolean
  failed: boolean
  domain: string
}

const initialState: OrganizationState = {
  availableProducts: [],
  branding: {
    isLoading: true,
    failed: false,
    default: false,
  },
  isLoading: true,
  failed: false,
  domain: document.location.hostname,
};

export const organization = handleActions({
  [fetchOrganizationConfigSucceeded as any]: (state, { payload }: any) => ({
    ...state,
    isLoading: false,
    availableProducts: payload,
  }),
  [fetchOrganizationConfigFailed as any]: state => ({
    ...state,
    isLoading: false,
    failed: true,
  }),
  [fetchOrganizationBrandingInitialized as any]: state => ({
    ...state,
    branding: {
      ...state.branding,
      isLoading: true,
      failed: false,
    },
  }),
  [fetchOrganizationBrandingSucceeded as any]: (state, { payload }: any) => ({
    ...state,
    branding: {
      isLoading: false,
      failed: false,
      default: payload.default,
    },
  }),
  [fetchOrganizationBrandingFailed as any]: state => ({
    ...state,
    branding: {
      ...initialState.branding,
      isLoading: false,
      failed: true,
    },
  }),
}, initialState);
