import {
  decorate,
  injectable,
} from 'inversify';

/**
 * Used to provide Service for Platform
 *
 * @returns {ClassDecorator} Decorator for services
 */
export const Service = (): ClassDecorator => (
  ((constructor: any): void => {
    decorate(injectable(), constructor);
  }) as ClassDecorator
);
