import React, { ComponentType, lazy } from 'react';
import { DIContext, IContext } from './DIProvider';

export function withInject<T = any>(
  propsMap: {[key: string]: string},
): <Props extends object>(WrappedComponent: ComponentType<Props>) =>
  ComponentType<Omit<Props, keyof T>> {
  return <Props extends Omit<Props, keyof T>>(WrappedComponent: any) => (
    class Injected extends React.Component<Props> {
      static contextType = DIContext;

      private additionalProps: any;

      constructor(props: Props, context: IContext) {
        super(props, context);

        const { container, lazyCrates } = this.context as IContext;

        this.additionalProps = Object.entries(propsMap)
          .reduce((acc: any, [propName, serviceName]) => {
            if (!container) {
              return acc;
            }

            const crateName: string = (serviceName as any).split('.')[0];

            if (Object.keys(lazyCrates).includes(crateName)) {
              acc[propName] = lazy(async (): Promise<{
                default: any
              }> => {
                await lazyCrates[crateName].load();

                return {
                  default: container.get(serviceName),
                };
              });
            } else {
              acc[propName] = container.get(serviceName);
            }

            // eslint-disable-next-line consistent-return
            return acc;
          }, {});
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            {...this.additionalProps}
          />
        );
      }
    }
  );
}
