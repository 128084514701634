import { Service } from '@piwikpro/platform';

@Service()
export class ConflictError extends Error {
  name = 'ConflictError';

  errors: Array<any> = [];

  constructor(name: string, public readonly apiResponseErrors: Array<any>) {
    super(name);

    this.errors = apiResponseErrors;
  }
}
