export class Pagination {
  constructor(items, total, limit, page) {
    this.items = items;
    this.total = total;
    this.limit = limit;
    this.page = page;

    if (this.total < this.offset) {
      this.page = 1;
    }
  }

  static fromQueryParams(items, total, params) {
    return new Pagination(
      items,
      total,
      Number(params.limit),
      Number(params.page),
    );
  }

  get offset() {
    return (this.page - 1) * this.limit;
  }

  getPageFromOffset(offset) {
    return (offset / this.limit) + 1;
  }

  getPage() {
    return this.page;
  }

  getLimit() {
    return this.limit;
  }

  getItems() {
    return this.items.slice(this.offset, this.offset + this.limit);
  }
}
