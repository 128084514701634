import React from 'react';

export interface TitlePart {
  value?: string
  important?: boolean
  subset?: boolean
}

export interface TitleContextValue {
  [key: number]: TitlePart
}

export const TitleContext = React.createContext<TitleContextValue>([]);

export interface TitleProviderProps {
  parts: TitleContextValue
  children: React.ReactNode
}

export class TitleProvider extends React.Component<TitleProviderProps> {
  static defaultProps: TitleProviderProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    parts: [],
    // eslint-disable-next-line react/default-props-match-prop-types
    children: null,
  }

  render() {
    const { children, parts } = this.props;

    return (
      <TitleContext.Provider value={parts}>
        {children}
      </TitleContext.Provider>
    );
  }
}
