import Preference from '../Preference';

export default class ListPreference extends Preference {
  static defaults = {}

  static changeDefault(name, value) {
    this.defaults[name] = value;

    return this;
  }

  constructor(values, scopeName, scopeDefaults = {}) {
    super('List', values, scopeName, scopeDefaults);
  }

  set limit(value) {
    this.value.limit = value;
  }

  get limit() {
    return this.value.limit || ListPreference.defaults.limit;
  }
}
