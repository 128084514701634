export type Region = {
  id: string
  name: string
  currency: string
  value: string
}[];

export default [
  {
    id: 'BD',
    name: 'Bangladesh - BD',
    currency: 'BDT',
    value: 'BD',
  },
  {
    id: 'BE',
    name: 'Belgium - BE',
    currency: 'EUR',
    value: 'BE',
  },
  {
    id: 'BF',
    name: 'Burkina Faso - BF',
    currency: 'XOF',
    value: 'BF',
  },
  {
    id: 'BG',
    name: 'Bulgaria - BG',
    currency: 'BGN',
    value: 'BG',
  },
  {
    id: 'BA',
    name: 'Bosnia and Herzegovina - BA',
    currency: 'BAM',
    value: 'BA',
  },
  {
    id: 'BB',
    name: 'Barbados - BB',
    currency: 'BBD',
    value: 'BB',
  },
  {
    id: 'WF',
    name: 'Wallis and Futuna - WF',
    currency: 'XPF',
    value: 'WF',
  },
  {
    id: 'BL',
    name: 'Saint Barthelemy - BL',
    currency: 'EUR',
    value: 'BL',
  },
  {
    id: 'BM',
    name: 'Bermuda - BM',
    currency: 'BMD',
    value: 'BM',
  },
  {
    id: 'BN',
    name: 'Brunei - BN',
    currency: 'BND',
    value: 'BN',
  },
  {
    id: 'BO',
    name: 'Bolivia - BO',
    currency: 'BOB',
    value: 'BO',
  },
  {
    id: 'BH',
    name: 'Bahrain - BH',
    currency: 'BHD',
    value: 'BH',
  },
  {
    id: 'BI',
    name: 'Burundi - BI',
    currency: 'BIF',
    value: 'BI',
  },
  {
    id: 'BJ',
    name: 'Benin - BJ',
    currency: 'XOF',
    value: 'BJ',
  },
  {
    id: 'BT',
    name: 'Bhutan - BT',
    currency: 'BTN',
    value: 'BT',
  },
  {
    id: 'JM',
    name: 'Jamaica - JM',
    currency: 'JMD',
    value: 'JM',
  },
  {
    id: 'BV',
    name: 'Bouvet Island - BV',
    currency: 'NOK',
    value: 'BV',
  },
  {
    id: 'BW',
    name: 'Botswana - BW',
    currency: 'BWP',
    value: 'BW',
  },
  {
    id: 'WS',
    name: 'Samoa - WS',
    currency: 'WST',
    value: 'WS',
  },
  {
    id: 'BQ',
    name: 'Bonaire, Saint Eustatius and Saba  - BQ',
    currency: 'USD',
    value: 'BQ',
  },
  {
    id: 'BR',
    name: 'Brazil - BR',
    currency: 'BRL',
    value: 'BR',
  },
  {
    id: 'BS',
    name: 'Bahamas - BS',
    currency: 'BSD',
    value: 'BS',
  },
  {
    id: 'JE',
    name: 'Jersey - JE',
    currency: 'GBP',
    value: 'JE',
  },
  {
    id: 'BY',
    name: 'Belarus - BY',
    currency: 'BYR',
    value: 'BY',
  },
  {
    id: 'BZ',
    name: 'Belize - BZ',
    currency: 'BZD',
    value: 'BZ',
  },
  {
    id: 'RU',
    name: 'Russia - RU',
    currency: 'RUB',
    value: 'RU',
  },
  {
    id: 'RW',
    name: 'Rwanda - RW',
    currency: 'RWF',
    value: 'RW',
  },
  {
    id: 'RS',
    name: 'Serbia - RS',
    currency: 'RSD',
    value: 'RS',
  },
  {
    id: 'TL',
    name: 'East Timor - TL',
    currency: 'USD',
    value: 'TL',
  },
  {
    id: 'RE',
    name: 'Reunion - RE',
    currency: 'EUR',
    value: 'RE',
  },
  {
    id: 'TM',
    name: 'Turkmenistan - TM',
    currency: 'TMT',
    value: 'TM',
  },
  {
    id: 'TJ',
    name: 'Tajikistan - TJ',
    currency: 'TJS',
    value: 'TJ',
  },
  {
    id: 'RO',
    name: 'Romania - RO',
    currency: 'RON',
    value: 'RO',
  },
  {
    id: 'TK',
    name: 'Tokelau - TK',
    currency: 'NZD',
    value: 'TK',
  },
  {
    id: 'GW',
    name: 'Guinea-Bissau - GW',
    currency: 'XOF',
    value: 'GW',
  },
  {
    id: 'GU',
    name: 'Guam - GU',
    currency: 'USD',
    value: 'GU',
  },
  {
    id: 'GT',
    name: 'Guatemala - GT',
    currency: 'GTQ',
    value: 'GT',
  },
  {
    id: 'GS',
    name: 'South Georgia and the South Sandwich Islands - GS',
    currency: 'GBP',
    value: 'GS',
  },
  {
    id: 'GR',
    name: 'Greece - GR',
    currency: 'EUR',
    value: 'GR',
  },
  {
    id: 'GQ',
    name: 'Equatorial Guinea - GQ',
    currency: 'XAF',
    value: 'GQ',
  },
  {
    id: 'GP',
    name: 'Guadeloupe - GP',
    currency: 'EUR',
    value: 'GP',
  },
  {
    id: 'JP',
    name: 'Japan - JP',
    currency: 'JPY',
    value: 'JP',
  },
  {
    id: 'GY',
    name: 'Guyana - GY',
    currency: 'GYD',
    value: 'GY',
  },
  {
    id: 'GG',
    name: 'Guernsey - GG',
    currency: 'GBP',
    value: 'GG',
  },
  {
    id: 'GF',
    name: 'French Guiana - GF',
    currency: 'EUR',
    value: 'GF',
  },
  {
    id: 'GE',
    name: 'Georgia - GE',
    currency: 'GEL',
    value: 'GE',
  },
  {
    id: 'GD',
    name: 'Grenada - GD',
    currency: 'XCD',
    value: 'GD',
  },
  {
    id: 'GB',
    name: 'United Kingdom - GB',
    currency: 'GBP',
    value: 'GB',
  },
  {
    id: 'GA',
    name: 'Gabon - GA',
    currency: 'XAF',
    value: 'GA',
  },
  {
    id: 'SV',
    name: 'El Salvador - SV',
    currency: 'USD',
    value: 'SV',
  },
  {
    id: 'GN',
    name: 'Guinea - GN',
    currency: 'GNF',
    value: 'GN',
  },
  {
    id: 'GM',
    name: 'Gambia - GM',
    currency: 'GMD',
    value: 'GM',
  },
  {
    id: 'GL',
    name: 'Greenland - GL',
    currency: 'DKK',
    value: 'GL',
  },
  {
    id: 'GI',
    name: 'Gibraltar - GI',
    currency: 'GIP',
    value: 'GI',
  },
  {
    id: 'GH',
    name: 'Ghana - GH',
    currency: 'GHS',
    value: 'GH',
  },
  {
    id: 'OM',
    name: 'Oman - OM',
    currency: 'OMR',
    value: 'OM',
  },
  {
    id: 'TN',
    name: 'Tunisia - TN',
    currency: 'TND',
    value: 'TN',
  },
  {
    id: 'JO',
    name: 'Jordan - JO',
    currency: 'JOD',
    value: 'JO',
  },
  {
    id: 'HR',
    name: 'Croatia - HR',
    currency: 'HRK',
    value: 'HR',
  },
  {
    id: 'HT',
    name: 'Haiti - HT',
    currency: 'HTG',
    value: 'HT',
  },
  {
    id: 'HU',
    name: 'Hungary - HU',
    currency: 'HUF',
    value: 'HU',
  },
  {
    id: 'HK',
    name: 'Hong Kong - HK',
    currency: 'HKD',
    value: 'HK',
  },
  {
    id: 'HN',
    name: 'Honduras - HN',
    currency: 'HNL',
    value: 'HN',
  },
  {
    id: 'HM',
    name: 'Heard Island and McDonald Islands - HM',
    currency: 'AUD',
    value: 'HM',
  },
  {
    id: 'VE',
    name: 'Venezuela - VE',
    currency: 'VEF',
    value: 'VE',
  },
  {
    id: 'PR',
    name: 'Puerto Rico - PR',
    currency: 'USD',
    value: 'PR',
  },
  {
    id: 'PS',
    name: 'Palestinian Territory - PS',
    currency: 'ILS',
    value: 'PS',
  },
  {
    id: 'PW',
    name: 'Palau - PW',
    currency: 'USD',
    value: 'PW',
  },
  {
    id: 'PT',
    name: 'Portugal - PT',
    currency: 'EUR',
    value: 'PT',
  },
  {
    id: 'SJ',
    name: 'Svalbard and Jan Mayen - SJ',
    currency: 'NOK',
    value: 'SJ',
  },
  {
    id: 'PY',
    name: 'Paraguay - PY',
    currency: 'PYG',
    value: 'PY',
  },
  {
    id: 'IQ',
    name: 'Iraq - IQ',
    currency: 'IQD',
    value: 'IQ',
  },
  {
    id: 'PA',
    name: 'Panama - PA',
    currency: 'PAB',
    value: 'PA',
  },
  {
    id: 'PF',
    name: 'French Polynesia - PF',
    currency: 'XPF',
    value: 'PF',
  },
  {
    id: 'PG',
    name: 'Papua New Guinea - PG',
    currency: 'PGK',
    value: 'PG',
  },
  {
    id: 'PE',
    name: 'Peru - PE',
    currency: 'PEN',
    value: 'PE',
  },
  {
    id: 'PK',
    name: 'Pakistan - PK',
    currency: 'PKR',
    value: 'PK',
  },
  {
    id: 'PH',
    name: 'Philippines - PH',
    currency: 'PHP',
    value: 'PH',
  },
  {
    id: 'PN',
    name: 'Pitcairn - PN',
    currency: 'NZD',
    value: 'PN',
  },
  {
    id: 'PL',
    name: 'Poland - PL',
    currency: 'PLN',
    value: 'PL',
  },
  {
    id: 'PM',
    name: 'Saint Pierre and Miquelon - PM',
    currency: 'EUR',
    value: 'PM',
  },
  {
    id: 'ZM',
    name: 'Zambia - ZM',
    currency: 'ZMK',
    value: 'ZM',
  },
  {
    id: 'EH',
    name: 'Western Sahara - EH',
    currency: 'MAD',
    value: 'EH',
  },
  {
    id: 'EE',
    name: 'Estonia - EE',
    currency: 'EUR',
    value: 'EE',
  },
  {
    id: 'EG',
    name: 'Egypt - EG',
    currency: 'EGP',
    value: 'EG',
  },
  {
    id: 'ZA',
    name: 'South Africa - ZA',
    currency: 'ZAR',
    value: 'ZA',
  },
  {
    id: 'EC',
    name: 'Ecuador - EC',
    currency: 'USD',
    value: 'EC',
  },
  {
    id: 'IT',
    name: 'Italy - IT',
    currency: 'EUR',
    value: 'IT',
  },
  {
    id: 'VN',
    name: 'Vietnam - VN',
    currency: 'VND',
    value: 'VN',
  },
  {
    id: 'SB',
    name: 'Solomon Islands - SB',
    currency: 'SBD',
    value: 'SB',
  },
  {
    id: 'ET',
    name: 'Ethiopia - ET',
    currency: 'ETB',
    value: 'ET',
  },
  {
    id: 'SO',
    name: 'Somalia - SO',
    currency: 'SOS',
    value: 'SO',
  },
  {
    id: 'ZW',
    name: 'Zimbabwe - ZW',
    currency: 'ZWL',
    value: 'ZW',
  },
  {
    id: 'SA',
    name: 'Saudi Arabia - SA',
    currency: 'SAR',
    value: 'SA',
  },
  {
    id: 'ES',
    name: 'Spain - ES',
    currency: 'EUR',
    value: 'ES',
  },
  {
    id: 'ER',
    name: 'Eritrea - ER',
    currency: 'ERN',
    value: 'ER',
  },
  {
    id: 'ME',
    name: 'Montenegro - ME',
    currency: 'EUR',
    value: 'ME',
  },
  {
    id: 'MD',
    name: 'Moldova - MD',
    currency: 'MDL',
    value: 'MD',
  },
  {
    id: 'MG',
    name: 'Madagascar - MG',
    currency: 'MGA',
    value: 'MG',
  },
  {
    id: 'MF',
    name: 'Saint Martin - MF',
    currency: 'EUR',
    value: 'MF',
  },
  {
    id: 'MA',
    name: 'Morocco - MA',
    currency: 'MAD',
    value: 'MA',
  },
  {
    id: 'MC',
    name: 'Monaco - MC',
    currency: 'EUR',
    value: 'MC',
  },
  {
    id: 'UZ',
    name: 'Uzbekistan - UZ',
    currency: 'UZS',
    value: 'UZ',
  },
  {
    id: 'MM',
    name: 'Myanmar - MM',
    currency: 'MMK',
    value: 'MM',
  },
  {
    id: 'ML',
    name: 'Mali - ML',
    currency: 'XOF',
    value: 'ML',
  },
  {
    id: 'MO',
    name: 'Macao - MO',
    currency: 'MOP',
    value: 'MO',
  },
  {
    id: 'MN',
    name: 'Mongolia - MN',
    currency: 'MNT',
    value: 'MN',
  },
  {
    id: 'MH',
    name: 'Marshall Islands - MH',
    currency: 'USD',
    value: 'MH',
  },
  {
    id: 'MK',
    name: 'Macedonia - MK',
    currency: 'MKD',
    value: 'MK',
  },
  {
    id: 'MU',
    name: 'Mauritius - MU',
    currency: 'MUR',
    value: 'MU',
  },
  {
    id: 'MT',
    name: 'Malta - MT',
    currency: 'EUR',
    value: 'MT',
  },
  {
    id: 'MW',
    name: 'Malawi - MW',
    currency: 'MWK',
    value: 'MW',
  },
  {
    id: 'MV',
    name: 'Maldives - MV',
    currency: 'MVR',
    value: 'MV',
  },
  {
    id: 'MQ',
    name: 'Martinique - MQ',
    currency: 'EUR',
    value: 'MQ',
  },
  {
    id: 'MP',
    name: 'Northern Mariana Islands - MP',
    currency: 'USD',
    value: 'MP',
  },
  {
    id: 'MS',
    name: 'Montserrat - MS',
    currency: 'XCD',
    value: 'MS',
  },
  {
    id: 'MR',
    name: 'Mauritania - MR',
    currency: 'MRO',
    value: 'MR',
  },
  {
    id: 'IM',
    name: 'Isle of Man - IM',
    currency: 'GBP',
    value: 'IM',
  },
  {
    id: 'UG',
    name: 'Uganda - UG',
    currency: 'UGX',
    value: 'UG',
  },
  {
    id: 'TZ',
    name: 'Tanzania - TZ',
    currency: 'TZS',
    value: 'TZ',
  },
  {
    id: 'MY',
    name: 'Malaysia - MY',
    currency: 'MYR',
    value: 'MY',
  },
  {
    id: 'MX',
    name: 'Mexico - MX',
    currency: 'MXN',
    value: 'MX',
  },
  {
    id: 'IL',
    name: 'Israel - IL',
    currency: 'ILS',
    value: 'IL',
  },
  {
    id: 'FR',
    name: 'France - FR',
    currency: 'EUR',
    value: 'FR',
  },
  {
    id: 'IO',
    name: 'British Indian Ocean Territory - IO',
    currency: 'USD',
    value: 'IO',
  },
  {
    id: 'SH',
    name: 'Saint Helena - SH',
    currency: 'SHP',
    value: 'SH',
  },
  {
    id: 'FI',
    name: 'Finland - FI',
    currency: 'EUR',
    value: 'FI',
  },
  {
    id: 'FJ',
    name: 'Fiji - FJ',
    currency: 'FJD',
    value: 'FJ',
  },
  {
    id: 'FK',
    name: 'Falkland Islands - FK',
    currency: 'FKP',
    value: 'FK',
  },
  {
    id: 'FM',
    name: 'Micronesia - FM',
    currency: 'USD',
    value: 'FM',
  },
  {
    id: 'FO',
    name: 'Faroe Islands - FO',
    currency: 'DKK',
    value: 'FO',
  },
  {
    id: 'NI',
    name: 'Nicaragua - NI',
    currency: 'NIO',
    value: 'NI',
  },
  {
    id: 'NL',
    name: 'Netherlands - NL',
    currency: 'EUR',
    value: 'NL',
  },
  {
    id: 'NO',
    name: 'Norway - NO',
    currency: 'NOK',
    value: 'NO',
  },
  {
    id: 'NA',
    name: 'Namibia - NA',
    currency: 'NAD',
    value: 'NA',
  },
  {
    id: 'VU',
    name: 'Vanuatu - VU',
    currency: 'VUV',
    value: 'VU',
  },
  {
    id: 'NC',
    name: 'New Caledonia - NC',
    currency: 'XPF',
    value: 'NC',
  },
  {
    id: 'NE',
    name: 'Niger - NE',
    currency: 'XOF',
    value: 'NE',
  },
  {
    id: 'NF',
    name: 'Norfolk Island - NF',
    currency: 'AUD',
    value: 'NF',
  },
  {
    id: 'NG',
    name: 'Nigeria - NG',
    currency: 'NGN',
    value: 'NG',
  },
  {
    id: 'NZ',
    name: 'New Zealand - NZ',
    currency: 'NZD',
    value: 'NZ',
  },
  {
    id: 'NP',
    name: 'Nepal - NP',
    currency: 'NPR',
    value: 'NP',
  },
  {
    id: 'NR',
    name: 'Nauru - NR',
    currency: 'AUD',
    value: 'NR',
  },
  {
    id: 'NU',
    name: 'Niue - NU',
    currency: 'NZD',
    value: 'NU',
  },
  {
    id: 'CK',
    name: 'Cook Islands - CK',
    currency: 'NZD',
    value: 'CK',
  },
  {
    id: 'XK',
    name: 'Kosovo - XK',
    currency: 'EUR',
    value: 'XK',
  },
  {
    id: 'CI',
    name: 'Ivory Coast - CI',
    currency: 'XOF',
    value: 'CI',
  },
  {
    id: 'CH',
    name: 'Switzerland - CH',
    currency: 'CHF',
    value: 'CH',
  },
  {
    id: 'CO',
    name: 'Colombia - CO',
    currency: 'COP',
    value: 'CO',
  },
  {
    id: 'CN',
    name: 'China - CN',
    currency: 'CNY',
    value: 'CN',
  },
  {
    id: 'CM',
    name: 'Cameroon - CM',
    currency: 'XAF',
    value: 'CM',
  },
  {
    id: 'CL',
    name: 'Chile - CL',
    currency: 'CLP',
    value: 'CL',
  },
  {
    id: 'CC',
    name: 'Cocos Islands - CC',
    currency: 'AUD',
    value: 'CC',
  },
  {
    id: 'CA',
    name: 'Canada - CA',
    currency: 'CAD',
    value: 'CA',
  },
  {
    id: 'CG',
    name: 'Republic of the Congo - CG',
    currency: 'XAF',
    value: 'CG',
  },
  {
    id: 'CF',
    name: 'Central African Republic - CF',
    currency: 'XAF',
    value: 'CF',
  },
  {
    id: 'CD',
    name: 'Democratic Republic of the Congo - CD',
    currency: 'CDF',
    value: 'CD',
  },
  {
    id: 'CZ',
    name: 'Czech Republic - CZ',
    currency: 'CZK',
    value: 'CZ',
  },
  {
    id: 'CY',
    name: 'Cyprus - CY',
    currency: 'EUR',
    value: 'CY',
  },
  {
    id: 'CX',
    name: 'Christmas Island - CX',
    currency: 'AUD',
    value: 'CX',
  },
  {
    id: 'CR',
    name: 'Costa Rica - CR',
    currency: 'CRC',
    value: 'CR',
  },
  {
    id: 'CW',
    name: 'Curacao - CW',
    currency: 'ANG',
    value: 'CW',
  },
  {
    id: 'CV',
    name: 'Cape Verde - CV',
    currency: 'CVE',
    value: 'CV',
  },
  {
    id: 'CU',
    name: 'Cuba - CU',
    currency: 'CUP',
    value: 'CU',
  },
  {
    id: 'SZ',
    name: 'Swaziland - SZ',
    currency: 'SZL',
    value: 'SZ',
  },
  {
    id: 'SY',
    name: 'Syria - SY',
    currency: 'SYP',
    value: 'SY',
  },
  {
    id: 'SX',
    name: 'Sint Maarten - SX',
    currency: 'ANG',
    value: 'SX',
  },
  {
    id: 'KG',
    name: 'Kyrgyzstan - KG',
    currency: 'KGS',
    value: 'KG',
  },
  {
    id: 'KE',
    name: 'Kenya - KE',
    currency: 'KES',
    value: 'KE',
  },
  {
    id: 'SS',
    name: 'South Sudan - SS',
    currency: 'SSP',
    value: 'SS',
  },
  {
    id: 'SR',
    name: 'Suriname - SR',
    currency: 'SRD',
    value: 'SR',
  },
  {
    id: 'KI',
    name: 'Kiribati - KI',
    currency: 'AUD',
    value: 'KI',
  },
  {
    id: 'KH',
    name: 'Cambodia - KH',
    currency: 'KHR',
    value: 'KH',
  },
  {
    id: 'KN',
    name: 'Saint Kitts and Nevis - KN',
    currency: 'XCD',
    value: 'KN',
  },
  {
    id: 'KM',
    name: 'Comoros - KM',
    currency: 'KMF',
    value: 'KM',
  },
  {
    id: 'ST',
    name: 'Sao Tome and Principe - ST',
    currency: 'STD',
    value: 'ST',
  },
  {
    id: 'SK',
    name: 'Slovakia - SK',
    currency: 'EUR',
    value: 'SK',
  },
  {
    id: 'KR',
    name: 'South Korea - KR',
    currency: 'KRW',
    value: 'KR',
  },
  {
    id: 'SI',
    name: 'Slovenia - SI',
    currency: 'EUR',
    value: 'SI',
  },
  {
    id: 'KP',
    name: 'North Korea - KP',
    currency: 'KPW',
    value: 'KP',
  },
  {
    id: 'KW',
    name: 'Kuwait - KW',
    currency: 'KWD',
    value: 'KW',
  },
  {
    id: 'SN',
    name: 'Senegal - SN',
    currency: 'XOF',
    value: 'SN',
  },
  {
    id: 'SM',
    name: 'San Marino - SM',
    currency: 'EUR',
    value: 'SM',
  },
  {
    id: 'SL',
    name: 'Sierra Leone - SL',
    currency: 'SLL',
    value: 'SL',
  },
  {
    id: 'SC',
    name: 'Seychelles - SC',
    currency: 'SCR',
    value: 'SC',
  },
  {
    id: 'KZ',
    name: 'Kazakhstan - KZ',
    currency: 'KZT',
    value: 'KZ',
  },
  {
    id: 'KY',
    name: 'Cayman Islands - KY',
    currency: 'KYD',
    value: 'KY',
  },
  {
    id: 'SG',
    name: 'Singapore - SG',
    currency: 'SGD',
    value: 'SG',
  },
  {
    id: 'SE',
    name: 'Sweden - SE',
    currency: 'SEK',
    value: 'SE',
  },
  {
    id: 'SD',
    name: 'Sudan - SD',
    currency: 'SDG',
    value: 'SD',
  },
  {
    id: 'DO',
    name: 'Dominican Republic - DO',
    currency: 'DOP',
    value: 'DO',
  },
  {
    id: 'DM',
    name: 'Dominica - DM',
    currency: 'XCD',
    value: 'DM',
  },
  {
    id: 'DJ',
    name: 'Djibouti - DJ',
    currency: 'DJF',
    value: 'DJ',
  },
  {
    id: 'DK',
    name: 'Denmark - DK',
    currency: 'DKK',
    value: 'DK',
  },
  {
    id: 'VG',
    name: 'British Virgin Islands - VG',
    currency: 'USD',
    value: 'VG',
  },
  {
    id: 'DE',
    name: 'Germany - DE',
    currency: 'EUR',
    value: 'DE',
  },
  {
    id: 'YE',
    name: 'Yemen - YE',
    currency: 'YER',
    value: 'YE',
  },
  {
    id: 'DZ',
    name: 'Algeria - DZ',
    currency: 'DZD',
    value: 'DZ',
  },
  {
    id: 'US',
    name: 'United States - US',
    currency: 'USD',
    value: 'US',
  },
  {
    id: 'UY',
    name: 'Uruguay - UY',
    currency: 'UYU',
    value: 'UY',
  },
  {
    id: 'YT',
    name: 'Mayotte - YT',
    currency: 'EUR',
    value: 'YT',
  },
  {
    id: 'UM',
    name: 'United States Minor Outlying Islands - UM',
    currency: 'USD',
    value: 'UM',
  },
  {
    id: 'LB',
    name: 'Lebanon - LB',
    currency: 'LBP',
    value: 'LB',
  },
  {
    id: 'LC',
    name: 'Saint Lucia - LC',
    currency: 'XCD',
    value: 'LC',
  },
  {
    id: 'LA',
    name: 'Laos - LA',
    currency: 'LAK',
    value: 'LA',
  },
  {
    id: 'TV',
    name: 'Tuvalu - TV',
    currency: 'AUD',
    value: 'TV',
  },
  {
    id: 'TW',
    name: 'Taiwan - TW',
    currency: 'TWD',
    value: 'TW',
  },
  {
    id: 'TT',
    name: 'Trinidad and Tobago - TT',
    currency: 'TTD',
    value: 'TT',
  },
  {
    id: 'TR',
    name: 'Turkey - TR',
    currency: 'TRY',
    value: 'TR',
  },
  {
    id: 'LK',
    name: 'Sri Lanka - LK',
    currency: 'LKR',
    value: 'LK',
  },
  {
    id: 'LI',
    name: 'Liechtenstein - LI',
    currency: 'CHF',
    value: 'LI',
  },
  {
    id: 'LV',
    name: 'Latvia - LV',
    currency: 'EUR',
    value: 'LV',
  },
  {
    id: 'TO',
    name: 'Tonga - TO',
    currency: 'TOP',
    value: 'TO',
  },
  {
    id: 'LT',
    name: 'Lithuania - LT',
    currency: 'LTL',
    value: 'LT',
  },
  {
    id: 'LU',
    name: 'Luxembourg - LU',
    currency: 'EUR',
    value: 'LU',
  },
  {
    id: 'LR',
    name: 'Liberia - LR',
    currency: 'LRD',
    value: 'LR',
  },
  {
    id: 'LS',
    name: 'Lesotho - LS',
    currency: 'LSL',
    value: 'LS',
  },
  {
    id: 'TH',
    name: 'Thailand - TH',
    currency: 'THB',
    value: 'TH',
  },
  {
    id: 'TF',
    name: 'French Southern Territories - TF',
    currency: 'EUR',
    value: 'TF',
  },
  {
    id: 'TG',
    name: 'Togo - TG',
    currency: 'XOF',
    value: 'TG',
  },
  {
    id: 'TD',
    name: 'Chad - TD',
    currency: 'XAF',
    value: 'TD',
  },
  {
    id: 'TC',
    name: 'Turks and Caicos Islands - TC',
    currency: 'USD',
    value: 'TC',
  },
  {
    id: 'LY',
    name: 'Libya - LY',
    currency: 'LYD',
    value: 'LY',
  },
  {
    id: 'VA',
    name: 'Vatican - VA',
    currency: 'EUR',
    value: 'VA',
  },
  {
    id: 'VC',
    name: 'Saint Vincent and the Grenadines - VC',
    currency: 'XCD',
    value: 'VC',
  },
  {
    id: 'AE',
    name: 'United Arab Emirates - AE',
    currency: 'AED',
    value: 'AE',
  },
  {
    id: 'AD',
    name: 'Andorra - AD',
    currency: 'EUR',
    value: 'AD',
  },
  {
    id: 'AG',
    name: 'Antigua and Barbuda - AG',
    currency: 'XCD',
    value: 'AG',
  },
  {
    id: 'AF',
    name: 'Afghanistan - AF',
    currency: 'AFN',
    value: 'AF',
  },
  {
    id: 'AI',
    name: 'Anguilla - AI',
    currency: 'XCD',
    value: 'AI',
  },
  {
    id: 'VI',
    name: 'U.S. Virgin Islands - VI',
    currency: 'USD',
    value: 'VI',
  },
  {
    id: 'IS',
    name: 'Iceland - IS',
    currency: 'ISK',
    value: 'IS',
  },
  {
    id: 'IR',
    name: 'Iran - IR',
    currency: 'IRR',
    value: 'IR',
  },
  {
    id: 'AM',
    name: 'Armenia - AM',
    currency: 'AMD',
    value: 'AM',
  },
  {
    id: 'AL',
    name: 'Albania - AL',
    currency: 'ALL',
    value: 'AL',
  },
  {
    id: 'AO',
    name: 'Angola - AO',
    currency: 'AOA',
    value: 'AO',
  },
  {
    id: 'AQ',
    name: 'Antarctica - AQ',
    currency: 'USD',
    value: 'AQ',
  },
  {
    id: 'AS',
    name: 'American Samoa - AS',
    currency: 'USD',
    value: 'AS',
  },
  {
    id: 'AR',
    name: 'Argentina - AR',
    currency: 'ARS',
    value: 'AR',
  },
  {
    id: 'AU',
    name: 'Australia - AU',
    currency: 'AUD',
    value: 'AU',
  },
  {
    id: 'AT',
    name: 'Austria - AT',
    currency: 'EUR',
    value: 'AT',
  },
  {
    id: 'AW',
    name: 'Aruba - AW',
    currency: 'AWG',
    value: 'AW',
  },
  {
    id: 'IN',
    name: 'India - IN',
    currency: 'INR',
    value: 'IN',
  },
  {
    id: 'AX',
    name: 'Aland Islands - AX',
    currency: 'EUR',
    value: 'AX',
  },
  {
    id: 'AZ',
    name: 'Azerbaijan - AZ',
    currency: 'AZN',
    value: 'AZ',
  },
  {
    id: 'IE',
    name: 'Ireland - IE',
    currency: 'EUR',
    value: 'IE',
  },
  {
    id: 'ID',
    name: 'Indonesia - ID',
    currency: 'IDR',
    value: 'ID',
  },
  {
    id: 'UA',
    name: 'Ukraine - UA',
    currency: 'UAH',
    value: 'UA',
  },
  {
    id: 'QA',
    name: 'Qatar - QA',
    currency: 'QAR',
    value: 'QA',
  },
  {
    id: 'MZ',
    name: 'Mozambique - MZ',
    currency: 'MZN',
    value: 'MZ',
  },
];
