import React from 'react';
import { connect } from 'react-redux';
import {
  SplashScreen,
  View,
  Root,
  Spinner,
} from '@piwikpro/ui-components';
import {
  AccessContext,
  withInject,
} from '@piwikpro/platform';
import { UserRolePass } from './firewalls/UserRolePass';
import { Session } from './Session';

export interface SecuredProps {
  isLoading: boolean
  userRole: 'OWNER' | 'USER'
  session: Session
  failed: boolean
  accessManager: any
  config: any
  organization: any
  children: React.ReactNode
}

export class Secured extends React.Component<SecuredProps> {
  async componentDidMount() {
    const {
      session,
      organization,
    } = this.props;

    try {
      const userId = await session.get();
      await organization.fetchConfig(userId);
    // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  render() {
    const {
      children,
      userRole,
      isLoading,
      failed,
      accessManager: globalAccessManager,
      config,
    } = this.props;

    if (failed) {
      return (
        <Root>
          <View fullHeight>
            <SplashScreen type="internalServerError" />
          </View>
        </Root>
      );
    }

    if (isLoading) {
      return (
        <Spinner className="x-large" type="fullscreen" />
      );
    }


    const accessManager = globalAccessManager.create(
      new UserRolePass(config.get('ALL_ACCESS_USER'), userRole),
    );

    return (
      <AccessContext.Provider
        value={{
          accessManager,
          userRole,
        }}
      >
        {children}
      </AccessContext.Provider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userRole: state.session.userRole,
  isLoading: (!state.session.jwt && state.session.isLoading) || state.organization.isLoading,
  failed: state.session.failed,
});

export default withInject({
  session: 'AuthCrate.session',
  // TODO: To be removed after AccessControlCrate
  // will be created and take responsibility about user access
  accessManager: 'accessManager',
  config: 'config',
  organization: 'OrganizationCrate.organization',
})(
  connect(mapStateToProps, null)(Secured),
);
