import React from 'react';
import { connect } from 'react-redux';
import { withInject } from '@piwikpro/platform';
import { LicenseKeeper } from './LicenseKeeper';

export interface LicenseKeeperPanelProps {
  jwt: string
  licenseKeeper: LicenseKeeper
}

export interface LicenseKeeperPanelState {
  loaded: boolean
}

export class LicenseKeeperPanel extends React.Component<
LicenseKeeperPanelProps, LicenseKeeperPanelState
> {
  // flag to prevent loading license multiple times on JWT refresh
  state = {
    loaded: false,
  }

  fetchLicense() {
    const { licenseKeeper } = this.props;
    const { loaded } = this.state;

    if (!loaded) {
      licenseKeeper.fetchLicense().catch(() => {});
      this.setState(state => ({
        ...state,
        loaded: true,
      }));
    }
  }

  render() {
    const { jwt } = this.props;

    if (jwt) {
      this.fetchLicense();
    }
    return null;
  }
}

const mapStateToProps = (state: any) => ({
  jwt: state.session?.jwt,
});

export default withInject({
  licenseKeeper: 'LicenseKeeperCrate.license',
})(connect(mapStateToProps, null)(LicenseKeeperPanel));
