/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import { TitleService } from './TitleService';
import Title from './Title';
import { TitleProvider } from './TitleProvider';
import titleReducer from './reducer';

@Crate({
  name: 'TitleCrate',
  services: [
    { name: 'title', provide: TitleService },
  ],
  components: [
    { name: 'Title', provide: Title },
  ],
  reducers: {
    title: titleReducer,
  },
  providers: [
    TitleProvider,
  ],
})
export class TitleCrate {}
