import React from 'react';
import { SplashScreen } from '@piwikpro/ui-components';
import { AccessContext } from './AccessContext';


export default (accessContext, display = true) => ComponentToWrap => (
  class SecuredView extends React.Component {
    static contextType = AccessContext;

    render() {
      const { accessManager } = this.context;

      if (!accessManager.isGranted(accessContext)) {
        if (display) {
          return (
            <SplashScreen type="noPrivileges" />
          );
        }

        return null;
      }

      return (
        <ComponentToWrap
          {...this.props}
        />
      );
    }
  }
);
