import React from 'react';
import { Service, inject } from '@piwikpro/platform';
import type { Store } from 'redux';
import { createAction } from 'redux-actions';

export interface ConfirmationAttributes {
  messages?: Array<string>
  title: string
  name?: string
  applyBtnText?: string
  applyBtnAppearance?: string
  type?: 'simple' | 'customBody' | 'withCheck'
}

class ConfirmationRejectError extends Error {
  name = 'ConfirmationRejectError';
}

export const confirmationInitialized = createAction<Confirmation, Confirmation>(
  '@system/event/confirmations/show',
  (payload: Confirmation) => payload,
);
export const confirmationSucceeded = createAction(
  '@system/event/confirmations/pass',
);
export const confirmationFailed = createAction(
  '@system/event/confirmations/reject',
);

@Service()
export class Confirmation {
  private customModal: {
    body: React.FunctionComponent | null
  }

  constructor(
    @inject('store') private readonly store: Store,
  ) {
    this.customModal = {
      body: null,
    };
  }

  private setCustomModal(body: React.FunctionComponent | null) {
    this.customModal = {
      body,
    };
  }

  public getCustomBody(): React.FunctionComponent | null {
    return this.customModal.body;
  }

  confirm(
    attributes: ConfirmationAttributes,
    customBody?: React.FunctionComponent,
  ) {
    if (customBody) {
      this.setCustomModal(customBody || null);
    }

    return new Promise((resolve, reject) => {
      this.store.dispatch(confirmationInitialized({
        onSubmit: resolve,
        onCancel: () => reject(new ConfirmationRejectError()),
        messages: attributes.messages,
        title: attributes.title,
        name: attributes.name,
        type: attributes.type || 'simple',
        applyBtnText: attributes.applyBtnText,
        applyBtnAppearance: attributes.applyBtnAppearance,
      } as any));
    }).then(() => {
      this.store.dispatch(confirmationSucceeded());
    }).catch((err) => {
      this.store.dispatch(confirmationFailed());

      throw err;
    });
  }
}
