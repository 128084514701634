import { ConfigService } from './ConfigService';
import { ICrateConstructor } from './Crate';

export interface ImportedCrate {
  default: ICrateConstructor<any>
}

export interface LazyOptions {
  preloaded?: boolean
}

export interface ImportCrateFunction {
  (config: ConfigService): Promise<ImportedCrate>
}

export interface LazyCrateFactory {
  (config: ConfigService): Promise<ICrateConstructor<any>>
}

export class LazyCrate {
  constructor(
    public readonly crateName: string,
    private readonly importCrate: ImportCrateFunction,
    public readonly options: LazyOptions = {},
  ) {}

  async load(config: ConfigService): Promise<ICrateConstructor<any>> {
    return this.importCrate(config).then(imp => imp.default);
  }

  toPreload(): boolean {
    return this.options.preloaded || false;
  }
}

export function lazy(
  name: string,
  importCrate: ImportCrateFunction,
  options: LazyOptions = {},
): LazyCrate {
  return new LazyCrate(name, importCrate, options);
}
