import React, { Component } from 'react';
import { connect } from 'react-redux';
import Confirm from './components/Confirm';
import ConfirmWithCheck from './components/ConfirmWithCheck';
import CustomConfirm from './components/CustomConfirm';

export interface ConfirmationProps {
  type: 'simple' | 'customBody' | 'withCheck'
}

export class ConfirmationPanel extends Component<ConfirmationProps> {
  render() {
    const { type } = this.props;

    switch (type) {
      case 'customBody':
        return <CustomConfirm />;
      case 'withCheck':
        return <ConfirmWithCheck />;
      case 'simple':
        return <Confirm />;
      default:
        return null;
    }
  }
}

export default connect((state: any) => ({
  type: state.confirmation.type,
}))(ConfirmationPanel);
