import React from 'react';
import { AccessContext } from './AccessContext';

export default ComponentToWrap => (
  class WithAccess extends React.Component {
    static contextType = AccessContext;

    render() {
      const { accessManager } = this.context;

      return (
        <ComponentToWrap
          {...this.props}
          accessManager={accessManager}
        />
      );
    }
  }
);
