import { Service, inject } from '@piwikpro/platform';
import { NotificationAttributes, Notifications } from '../Notifications';

export interface ICustomNotificationConfig {
  pending?: Partial<NotificationAttributes>
  success?: Partial<NotificationAttributes>
  error?: Partial<NotificationAttributes>
}

const defaultConfig: {
  pending: NotificationAttributes
  success: NotificationAttributes
  error: NotificationAttributes
} = {
  pending: {
    text: 'components:notifications.update.processing',
    type: 'pending',
    autoCloseSeconds: 0,
  },
  success: {
    text: 'components:notifications.update.success',
    type: 'success',
    autoCloseSeconds: 5,
  },
  error: {
    text: 'components:notifications.update.failed',
    type: 'error',
    autoCloseSeconds: 5,
  },
};

@Service()
export class CustomNotification {
  private config: ICustomNotificationConfig = {};

  constructor(
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  withConfig(config: ICustomNotificationConfig): this {
    this.config = config;

    return this;
  }

  async intercept(req: any, next: any) {
    const id = this.notifications.push({
      ...defaultConfig.pending,
      ...this.config.pending,
    });

    try {
      const resp = await next(req);

      this.notifications.update(id, {
        ...defaultConfig.success,
        ...this.config.success,
      });

      return resp;
    } catch (err: any) {
      if (err.name === 'InvalidRequestError') {
        this.notifications.update(id, {
          text: 'components:notifications.update.invalid',
          type: 'error',
          autoCloseSeconds: 5,
          onClose: req.onNotificationRemove,
        });
      } else {
        this.notifications.update(id, {
          ...defaultConfig.error,
          ...this.config.error,
        });
      }

      throw err;
    }
  }
}
