import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '@piwikpro/http-crate/interfaces';
import { Notifications } from '@piwikpro/notification-crate';

@Service()
export class RateLimitInterceptor implements Interceptable {
  constructor(
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  async intercept<T>(req: Required<IRequest<T>>, next: NextFunction): Promise<T> {
    let resp;

    try {
      resp = await next(req);
    } catch (err: any) {
      if (err.name === 'TooManyRequestsError') {
        const id = 'rateLimitExceededNotification';

        this.notifications.push({
          id,
          text: 'platform:httpCrate.interceptors.rateLimits.text',
          description: 'platform:httpCrate.interceptors.rateLimits.description',
          type: 'error',
          autoCloseSeconds: 5,
          actions: [
            {
              id: 'ok',
              text: 'platform:httpCrate.interceptors.rateLimits.button.text',
              onClick: () => this.notifications.instantRemove(id),
            },
          ],
        });
      }

      throw err;
    }

    return resp;
  }
}
