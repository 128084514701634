import { AppPermissionPass } from '@piwikpro/apps-crate/firewall/AppPermissionPass';
import React from 'react';

export interface IAccessContext {
  userRole?: 'OWNER' | 'USER'
  accessManager?: ({
    isGranted: ({
      role,
      level,
    }: {
      role: 'OWNER' | 'USER'
      level?: string
    }) => boolean
    create: (
      level: string | AppPermissionPass
    ) => string | AppPermissionPass
  })
  userPermission?: string
}

export const AccessContext = React.createContext<IAccessContext>({});
