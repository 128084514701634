import { handleActions } from 'redux-actions';
import {
  Notification,
  pushNotification,
  removeNotification,
  updateNotification,
} from './Notifications';

export type EventType = 'ADD' | 'UPDATE'

export interface NotificationEvent {
  type: EventType
  notification: Notification
}

interface NotificationState {
  events: NotificationEvent[]
}

const initialState: NotificationState = {
  events: [],
};

export default handleActions<NotificationState, Notification>(
  {
    [pushNotification as any]: (state, { payload }) => ({
      ...state,
      events: [
        ...state.events, { notification: payload, type: 'ADD' },
      ],
    }),
    [updateNotification as any]: (state, { payload }) => ({
      ...state,
      events: [
        ...state.events, { notification: payload, type: 'UPDATE' },
      ],
    }),
    [removeNotification as any]: (state, { payload }: any) => ({
      ...state,
      events: state.events.filter(event => event.notification.id !== payload),
    }),
  },
  initialState,
);
