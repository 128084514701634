import timeZones from './config/timeZones';
import regions from './config/regions';
import currencies from './config/currencies';
import gdprPeriods from './config/gdprPeriods';

const env = {
  REACT_APP_STATIC_CACHE_BUSTER_URL: '/static/checksum',
  REACT_APP_LOCALES_URL: '/static/locales',
  REACT_APP_MODULES_URL: '/api/modules/v1',
  REACT_APP_OAUTH_URL: '/api/oauth/v1/client',
  REACT_APP_USERS_URL: '/api/users/v2',
  REACT_APP_LICENSE_KEEPER_URL: '/api/license-keeper/v1/license',
  REACT_APP_APPS_URL: '/api/apps/v2',
  REACT_APP_META_SITES_URL: '/api/meta-sites/v1',
  REACT_APP_USER_GROUPS_URL: '/api/user-groups/v1',
  REACT_APP_ACCESS_CONTROL_URL: '/api/access-control/v2',
  REACT_APP_ORGANIZATION_URL: '/api/organization/v1',
  REACT_APP_TRAFFIC_MONITOR_URL: '/api/traffic-monitor',
  REACT_APP_TRACKER_SETTINGS_URL: '/api/tracker/v2',
  REACT_APP_CONTAINER_SETTINGS_URL: '/api/container-settings/v1',
  REACT_APP_DATA_RETENTION_URL: '/api/data-retention/v1',
  REACT_APP_TAG_API_URL: '/tag/api/public',
  REACT_APP_USER_PANEL_BASE_URL: '/profile',
  REACT_APP_ADMINISTRATION_BASE_URL: '/administration',
  REACT_APP_SETTINGS_BASE_URL: '/',
  REACT_APP_INFO_BASE_URL: '/info',
  REACT_APP_ACCOUNT_SETUP_INVITED_USER_REDIRECT_PATH: '/analytics',
  REACT_APP_ACCOUNT_SETUP_FIRST_USER_REDIRECT_PATH: '/administration/apps?guides=first-steps',
  REACT_APP_ALL_ACCESS_USER: 'OWNER',
  REACT_APP_GA_MIGRATOR_URL: '/api/ga-migrator',
  REACT_APP_AVAILABLE_LANGUAGES: [
    {
      name: 'English',
      id: 'en',
      value: 'en',
    },
    {
      name: 'Deutsch',
      id: 'de',
      value: 'de',
    },
    {
      name: 'Suomi',
      id: 'fi',
      value: 'fi',
    },
    {
      name: 'Français',
      id: 'fr',
      value: 'fr',
    },
    {
      name: 'Nederlands',
      id: 'nl',
      value: 'nl',
    },
    {
      name: 'Svenska',
      id: 'sv',
      value: 'sv',
    },
    {
      name: 'Español',
      id: 'es',
      value: 'es',
    }, {
      name: 'Català',
      id: 'ca',
      value: 'ca',
    },
  ],
  REACT_APP_AVAILABLE_TIME_ZONES: timeZones,
  REACT_APP_AVAILABLE_REGIONS: regions,
  REACT_APP_AVAILABLE_CURRENCIES: currencies,
  REACT_APP_AVAILABLE_GDPR_PERIODS: gdprPeriods,
  REACT_APP_AVAILABLE_MODULES: {
    analytics: {
      id: 'analytics',
      label: 'Analytics',
      url: '/analytics/',
    },
    tag_manager: {
      id: 'tag_manager',
      label: 'Tag Manager',
      url: '/tag/',
    },
    consent_manager: {
      id: 'consent_manager',
      label: 'Consent Manager',
      url: '/consent/',
    },
    customer_data_platform: {
      id: 'customer_data_platform',
      label: 'Customer Data Platform',
      url: '/cdp/',
    },
  },
  REACT_APP_PAGINATION_ITEMS_PER_PAGE: 10,
  REACT_APP_PAGINATION_ITEMS_PER_PAGE_OPTIONS: [
    10,
    25,
    50,
  ],
  REACT_APP_SIDEBAR_LISTS_ITEMS_PER_PAGE: 100,
  REACT_APP_SIDEBAR_LISTS_SEARCH_DELAY: 500,
  REACT_APP_LIST_SEARCH_DELAY: 500,
  REACT_APP_REQUEST_DELAY: 200,
  REACT_APP_REQUEST_RETRY_NUMBER: 3,
  REACT_APP_REQUEST_RETRY_INTERVAL: 500,
  REACT_APP_DEFAULT_MODULE_URL_FALLBACK: '/administration',
  REACT_APP_USER_GROUP_DEFAULT_SORT: 'name',
  REACT_APP_USERS_DEFAULT_SORT: 'email',
  REACT_APP_APPS_DEFAULT_SORT: 'addedAt',
  REACT_APP_META_SITES_DEFAULT_SORT: 'name',
  REACT_APP_AUTH_URL: '/api/auth/v1',
  REACT_APP_SINGLE_DIGITAL_GATEWAY_URL: '/api/sdg/v1',
  ...process.env,
};

export { env };
