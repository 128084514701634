import Preference from '../Preference';

export default class ListSidebarPreference extends Preference {
  constructor(values, scopeName, scopeDefaults = {}) {
    super('ListSidebar', values, scopeName, scopeDefaults);
  }

  set sortBy(value) {
    this.addIntoScope('sortBy', value);
  }

  get sortBy() {
    return this.getFromScope('sortBy') || this.scopeDefaults.sortBy;
  }
}
