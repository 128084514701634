import { inject, Service } from '@piwikpro/platform';

export type LanguageTag = string

@Service()
export class TranslateService {
  constructor(@inject('TranslationCrate.i18n') private readonly i18n: any) {}

  async changeLanguage(newLanguage: LanguageTag): Promise<void> {
    return new Promise((resolve) => {
      this.i18n.changeLanguage(newLanguage, () => {
        resolve();
      });
    });
  }
}
