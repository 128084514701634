/* eslint-disable react/default-props-match-prop-types */
import React, { forwardRef as forwardRefType } from 'react';
import { WithTranslation } from 'react-i18next';
import { Tooltip, withTranslation } from '@piwikpro/ui-components';
import { AccessContext } from './AccessContext';

interface Props extends WithTranslation {
  disabled: boolean
  disabledActionTooltipText: string
  forwardRef: typeof forwardRefType
}

interface AdditionalProps {
  disabledActionTooltipText?: string
}

export default (accessContext: { role: 'OWNER' | 'USER', level?: string }) => function wrapper<T>(ComponentToWrap: React.ComponentType<T>) {
  class Action extends React.Component<Props> {
    static contextType = AccessContext;

    context!: React.ContextType<typeof AccessContext>;

    static defaultProps = {
      disabled: false,
      disabledActionTooltipText: '',
    };

    getTooltipContent() {
      const { disabled, disabledActionTooltipText, t } = this.props;
      const { accessManager, userPermission } = this.context;

      if (!accessManager!.isGranted(accessContext)) {
        return t('accessControl.permissions.permission.level.required.info',
          {
            currentPermission: userPermission,
            requiredPermission: accessContext.level || accessContext.role.toLowerCase(),
          });
      }

      if (disabled) {
        return disabledActionTooltipText || t('app.details.settings.general.use-global-settings.block-reason');
      }

      return '';
    }

    render() {
      const { forwardRef, ...props } = this.props;
      const tooltipText = this.getTooltipContent();

      if (tooltipText) {
        return (
          <Tooltip
            content={tooltipText}
          >
            <div>
              {/* @ts-ignore:next-line */}
              <ComponentToWrap {...props} ref={forwardRef} disabled />
            </div>
          </Tooltip>
        );
      }

      return (
        // @ts-ignore:next-line
        <ComponentToWrap {...this.props} ref={forwardRef} />
      );
    }
  }

  return withTranslation('administration', { withRef: true })(
    // @ts-ignore:next-line
    React.forwardRef((props: T & AdditionalProps, ref: React.ForwardedRef<T>) => (
      // @ts-ignore:next-line
      <Action {...props} forwardRef={ref} />
    )),
  );
};
