import { interfaces } from 'inversify';

export type AppPermissionLevel = {
  id: string
  value: string
  name: string
}[];

export default ({ container }: interfaces.Context) => {
  const i18n: any = container.get('TranslationCrate.i18n');

  return [
    {
      id: 'no-access',
      value: 'no-access',
      name: i18n.t('administration:apps.details.permissions.levels.no-access'),
    },
    {
      id: 'view',
      value: 'view',
      name: i18n.t('administration:apps.details.permissions.levels.view'),
    },
    {
      id: 'edit',
      value: 'edit',
      name: i18n.t('administration:apps.details.permissions.levels.edit'),
    },
    {
      id: 'edit-publish',
      value: 'edit-publish',
      name: i18n.t('administration:apps.details.permissions.levels.edit-publish'),
    },
    {
      id: 'manage',
      value: 'manage',
      name: i18n.t('administration:apps.details.permissions.levels.manage'),
    },
  ];
};
