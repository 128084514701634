import * as Yup from 'yup';
import get from 'lodash/get';
import {
  Service,
  inject,
} from '@piwikpro/platform';
import { validators } from './validators';

@Service()
export class FormValidator {
  private validators: ReturnType<typeof validators>;

  constructor(
    @inject('TranslationCrate.i18n') private readonly i18n: any,
    @inject('Yup') private readonly yup: typeof Yup,
  ) {
    /* eslint-disable */
    this.yup.addMethod(Yup.mixed, 'equalTo', function (this: any, ref: any, message: string) {
      return this.test('equalTo', message, function (this: any, value: any) {
        let refValue = this.resolve(ref);
        return !refValue || !value || value === refValue;
      })
    });

    this.yup.addMethod(Yup.mixed, 'notEqualTo', function (this: any, ref: any, message: string) {
      return this.test('equalTo', message, function (this: any, value: any) {
        let refValue = this.resolve(ref);
        return !refValue || !value || value !== refValue;
      })
    });

    this.yup.addMethod(Yup.mixed, 'dateTimeGreaterThen', function (this: any, ref: any, message: string) {
      return this.test('dateTimeGreaterThen', message, function (this: any, value: any) {
        let refValue = this.resolve(ref);

        return !refValue || !value || value > refValue;
      })
    });

    this.yup.addMethod(Yup.array, 'uniqueArray', function(
      options: {
        fieldName: string
        message: string
      }, mapper = (a: any) => a) {
      // @ts-ignore:next-line
      return this.test('uniqueArray', options.message, function(list: any[]) {
          // @ts-ignore:next-line
          const set = [...new Set(list.map(mapper))];
          const isUnique = list.length  === set.length;

          if (!isUnique) {
            const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
            // @ts-ignore:next-line
            throw this.createError({
              path: `${options.fieldName}.${idx}`,
              message: options.message,
            })
          }
          return isUnique;
      });
    });
    /* eslint-enable */
    this.setLocale({
      mixed: {
        required: this.i18n.t('profile:validation.errors.required'),
      },
      string: {
        trim: this.i18n.t('profile:validation.errors.required'),
        min: (number: any) => this.i18n.t('profile:validation.errors.too-short', { number: number.min }),
        max: (number: any) => this.i18n.t('profile:validation.errors.too-long', { number: number.max }),
      },
    });

    this.validators = validators(
      this.yup,
      this.i18n.t.bind(this.i18n),
    );
  }

  public createValidationSchema<T>(
    fieldsSchema: (
      availableValidators: FormValidator['validators'],
    ) => {[key: string]: Yup.Schema<T>},
  ): any {
    return this.getYup().object()
      .shape(fieldsSchema(this.validators));
  }

  public setLocale(locale: any): void {
    this.yup.setLocale(locale);
  }

  public isValueValid<T>(value: T, validatorName: string): boolean {
    return get(this.validators, validatorName).isValidSync(value);
  }

  public getYup(): typeof Yup {
    return this.yup;
  }
}
