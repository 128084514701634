import 'babel-polyfill';
import 'proxy-polyfill'; // required for IE11 (Proxy used in LicenseKeeperCrate panel)
import 'reflect-metadata';
import '@piwikpro/ui-components/components.css';
import {
  Platform,
} from '@piwikpro/platform';
import { PPMSCrate } from './PPMSCrate';

const platform = new Platform();

platform.run(PPMSCrate as any);
