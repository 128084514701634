import { connect } from 'react-redux';
import ConfirmWithCheck from './ConfirmWithCheck';

const mapStateToProps = (state: any) => ({
  messages: state.confirmation.messages,
  title: state.confirmation.title,
  applyBtnAppearance: state.confirmation.applyBtnAppearance,
  applyBtnText: state.confirmation.applyBtnText,
  name: state.confirmation.name,
  onSubmit: state.confirmation.onSubmit,
  onCancel: state.confirmation.onCancel,
});

export default connect(mapStateToProps, null)(ConfirmWithCheck as any);
