import React from 'react';
import { Container } from 'inversify';

export interface IContext {
  container?: Container
  lazyCrates: any
}

export const DIContext = React.createContext<IContext>({ lazyCrates: [] });

export interface IDIProviderProps {
  children: React.ReactElement
  container: Container
  lazyCrates: any
}

export class DIProvider extends React.Component<IDIProviderProps> {
  render() {
    const { children, container, lazyCrates } = this.props;

    return (
      <DIContext.Provider
        value={{ container, lazyCrates }}
      >
        {children}
      </DIContext.Provider>
    );
  }
}
