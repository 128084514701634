import { Service, inject } from '@piwikpro/platform';
import { removeToast } from '@piwikpro/ui-components';
import type { Store } from 'redux';
import { createAction } from 'redux-actions';
import { v4 } from 'uuid';

export type NotificationId = string;
export type NotificationStatus = 'success' | 'error' | 'pending';

export interface NotificationAction {
  id: NotificationId
  text: string
  onClick: () => void
}

export interface NotificationAttributes {
  id?: NotificationId
  type: NotificationStatus
  text: string
  description?: string
  autoCloseSeconds?: number
  actions?: NotificationAction[]
  onClose?: () => void
}

export interface Notification extends NotificationAttributes {
  id: NotificationId
}

export const pushNotification = createAction<Notification, Notification>(
  '@system/event/notifications/create',
  (notification: Notification) => notification,
);

export const updateNotification = createAction<
Partial<Notification>, NotificationId, Partial<NotificationAttributes>
>(
  '@system/event/notifications/update',
  (id: NotificationId, attributes: Partial<NotificationAttributes>) => ({ id, ...attributes }),
);

export const removeNotification = createAction<NotificationId, NotificationId>(
  '@system/event/notifications/delete',
  (id: NotificationId) => id,
);

@Service()
export class Notifications {
  constructor(
    @inject('store') private readonly store: Store,
  ) {}

  push(attributes: NotificationAttributes): NotificationId {
    const id = attributes.id || v4();

    this.store.dispatch(pushNotification({
      id,
      autoCloseSeconds: 5,
      ...attributes,
    }));

    return id;
  }

  update(id: NotificationId, attributes: Partial<NotificationAttributes>): void {
    this.store.dispatch(updateNotification(id, attributes));
  }

  remove(id: NotificationId): void {
    this.store.dispatch(removeNotification(id));
  }

  instantRemove(id: NotificationId): void {
    removeToast(id);
  }
}
