import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '../interfaces';
import {
  InvalidRequestError,
  InternalServerError,
  NotFoundError,
  ForbiddenError,
  UnauthorizedRequestError,
  ServiceUnavailableError,
  GoneError,
  ConflictError,
  FailedDependencyError,
  TooManyRequestsError,
} from '../httpErrors';

@Service()
export class JsonInterceptor implements Interceptable {
  constructor(
    @inject('TranslationCrate.i18n') private readonly i18n: any,
  ) {}

  async intercept<T=any>(req: Required<IRequest>, next: NextFunction): Promise<T | null> {
    let json: any;

    req.headers['Content-Type'] = 'application/json';
    req.body = JSON.stringify(req.body);

    const resp = await next(req);

    if (!resp.ok) {
      switch (resp.status) {
        case 400:
          json = await resp.json();
          throw new InvalidRequestError('Invalid Request', json);
        case 401:
          throw new UnauthorizedRequestError();
        case 403:
          // eslint-disable-next-line no-case-declarations
          json = await resp.json().catch(() => {});
          throw new ForbiddenError('ForbiddenError', json);
        case 404:
          throw new NotFoundError();
        case 409:
          json = await resp.json().catch(() => {});
          throw new ConflictError('Conflict', json);
        case 410:
          throw new GoneError();
        case 424:
          throw new FailedDependencyError();
        case 429:
          throw new TooManyRequestsError();
        case 500:
          throw new InternalServerError();
        case 503:
          throw new ServiceUnavailableError(resp.headers);
        default:
          throw new Error(resp.status);
      }
    } else if (resp.status === 204) {
      return null;
    }

    try {
      return await resp.json();
    } catch {
      return null;
    }
  }
}
