/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate, inject } from '@piwikpro/platform';
import Translator from './Translator';
import { i18nFactory, i18nConfigFactory } from './i18n';
import { TranslateService } from './TranslateService';

@Crate({
  name: 'TranslationCrate',
  providers: [
    Translator,
  ],
  services: [
    {
      name: 'i18n', provide: i18nFactory, type: 'factory', scope: 'dynamic',
    },
    {
      name: 'i18nConfigBuilder', provide: i18nConfigFactory, type: 'factory',
    },
    {
      name: 'translate', provide: TranslateService,
    },
  ],
})
export class TranslationCrate {
  constructor(
    @inject('config') private readonly config: any,
    @inject('TranslationCrate.i18nConfigBuilder') private readonly configBuilder: any,
  ) {}

  async onInit() {
    const preventResourcesFetch = this.config.get('NODE_ENV') === 'test' || this.config.get('NODE_ENV') === undefined;
    // 'container-settings' namespace needs to be initialized on the top level otherwise it causes error:  https://piwikpro.atlassian.net/browse/MID-140
    this.configBuilder.withNamespaces('administration', 'profile', 'container-settings');

    if (preventResourcesFetch) {
      this.configBuilder.withResources();
    }

    // TODO: remove this line once https://piwikpro.atlassian.net/browse/PPUCC-181 is done
    this.configBuilder.config.compatibilityJSON = undefined;

    await this.configBuilder.build(
      this.config.get('LOCALES_URL'),
      this.config.get('CACHE_BUSTER'),
    );
  }

  static configure(config: any) {
    config.set('I18N_NAMESPACES', []);
  }
}
