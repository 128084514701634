export class UserRolePass {
  constructor(
    private readonly allAccessRole: string,
    private readonly currentUserRole: string,
  ) {}

  isAllowed(context: any, next: () => boolean): boolean {
    if (this.currentUserRole === this.allAccessRole) return true;

    if (this.currentUserRole === 'USER' && context.role === this.allAccessRole) return false;

    return next();
  }
}
