/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import { HttpClient } from './HttpClient';
import { JsonApiInterceptor } from './httpInterceptors/JsonApiInterceptor';
import { JsonInterceptor } from './httpInterceptors/JsonInterceptor';
import { TextInterceptor } from './httpInterceptors/TextInterceptor';
import { FileInterceptor } from './httpInterceptors/FileInterceptor';
import { RateLimitInterceptor } from './httpInterceptors/RateLimitInterceptor';
import { MaintenanceInterceptor } from './httpInterceptors/MaintenanceInterceptor';

@Crate({
  name: 'HttpCrate',
  services: [
    { name: 'interceptors.JsonApi', provide: JsonApiInterceptor },
    { name: 'interceptors.Json', provide: JsonInterceptor },
    { name: 'interceptors.Text', provide: TextInterceptor },
    { name: 'interceptors.File', provide: FileInterceptor },
    { name: 'interceptors.RateLimitInterceptor', provide: RateLimitInterceptor },
    { name: 'interceptors.MaintenanceInterceptor', provide: MaintenanceInterceptor },
    { name: 'httpClient', provide: HttpClient },
  ],
})
export class HttpCrate {}
