import { handleActions, createAction } from 'redux-actions';
import { Response, ILicenseKeeperState } from './interfaces';

export const licenseFetchInitialized = createAction(
  '@platform/query/license/get/init',
);
export const licenseFetchSucceeded = createAction<Response, Response>(
  '@platform/query/license/get/success',
  (payload: Response) => payload,
);
export const licenseFetchFailed = createAction(
  '@platform/query/license/get/failed',
  (payload: string) => payload,
);
export const licenseUploadInitialized = createAction(
  '@platform/command/license/upload/init',
);
export const licenseUploadSucceeded = createAction(
  '@platform/command/license/upload/success',
);
export const licenseUploadFailed = createAction(
  '@platform/command/license/upload/failed',
);
export const licenseDecryptInitialized = createAction(
  '@platform/command/license/decrypt/init',
);
export const licenseDecryptSucceeded = createAction(
  '@platform/command/license/decrypt/success',
);
export const licenseDecryptFailed = createAction(
  '@platform/command/license/decrypt/failed',
);

const initialState = {
  isLoading: true,
  license: null,
  failed: false,
  failReason: null,
} as ILicenseKeeperState;

export default handleActions<ILicenseKeeperState, Response & string>({
  [licenseFetchInitialized.toString()]: () => initialState,
  [licenseFetchSucceeded.toString()]: (state, { payload }) => ({
    ...state,
    license: payload.data.attributes,
    isLoading: false,
  }),
  [licenseFetchFailed.toString()]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    failed: true,
    failReason: payload,
  }),
}, initialState);
