import type { Store } from 'redux';
import {
  Service,
  inject,
} from '@piwikpro/platform';
import { guideDeleteSucceeded } from './redux';

@Service()
export class Guides {
  constructor(
    @inject('store') private readonly store: Store,
    @inject('location') private readonly location: Location,
    @inject('RouterCrate.history') private readonly history: any,
  ) {}

  public closeGuide(name: string): void {
    const { history, location, store } = this;

    store.dispatch(guideDeleteSucceeded(name));
    history.push(location.pathname.replace(name, ''));
  }
}
