import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

import usersRules from './users';
import platformRules from './platform';
import appsRules from './apps';
import metaSitesRules from './metaSites';
import userGroupsRules from './userGroups';
import userPanelRules from './userPanel';
import auditLogRules from './auditLog';
import trackerSettingsRules from './trackerSettings';
import containerSettings from './containerSettings';
import dataRetention from './dataRetention';
import auditLogRetention from './auditLogRetention';
import auth from './auth';

export const validators = (yup: typeof Yup, t: TFunction) => ({
  users: usersRules(yup, t),
  apps: appsRules(yup, t),
  metaSites: metaSitesRules(yup),
  userGroups: userGroupsRules(yup),
  platform: platformRules(yup),
  userPanel: userPanelRules(yup, t),
  auditLog: auditLogRules(yup, t),
  trackerSettings: trackerSettingsRules(yup, t),
  containerSettings: containerSettings(yup, t),
  dataRetention: dataRetention(yup),
  auditLogRetention: auditLogRetention(yup),
  auth: auth(yup, t),
});
