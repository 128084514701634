// @ts-ignore
import { Service, inject } from '@piwikpro/platform';
import type { Store } from 'redux';
import { LicenseAttributes } from './interfaces';

export const CORE_TRAFFIC_LIMIT_DEFAULT = 500000;

@Service()
export class LicenseLimitsService {
  private license: LicenseAttributes | null = null;

  private unlimitedOptionsValues: any = {
    apps_limit: -1,
  };

  constructor(
    @inject('config') private readonly config: any,
    @inject('store') private readonly store: Store,
  ) {
    return new Proxy(this, {
      get: (target, fncName) => {
        const { licenseKeeper: { license } } = this.store.getState();
        this.license = license;

        if (!license) {
          return () => false; // don't apply limits in UI
        }

        return target[fncName as keyof LicenseLimitsService];
      },
    });
  }

  public hasAnalyticsModule(): boolean {
    // FIXME: not sure about assertion after that
    return this.license?.modules[this.config.get('AVAILABLE_MODULES').analytics.id] !== undefined;
  }

  public isCorePlus(): boolean {
    const { plan: { type, traffic_limit: trafficLimit } } = this.license!;

    return type === 'core' && trafficLimit > CORE_TRAFFIC_LIMIT_DEFAULT;
  }

  public hasReachedModuleOptionLimit(
    {
      moduleName,
      option,
      value,
    }: {
      moduleName: string
      option: string
      value: number
    },
  ): boolean {
    const optionValue = this.license?.modules[moduleName]?.[option];

    if (optionValue === this.unlimitedOptionsValues[option]) {
      return false;
    }

    return optionValue <= value;
  }
}
