import { Service, inject } from '@piwikpro/platform';
import { Notifications } from '../Notifications';

@Service()
export class NotifyAboutDelete {
  constructor(
    @inject('NotificationCrate.notifications') private readonly notifications: Notifications,
  ) {}

  async intercept(req: any, next: any) {
    const id = this.notifications.push({
      text: 'components:notifications.delete.processing',
      type: 'pending',
      autoCloseSeconds: 0,
      onClose: req.onNotificationRemove,
    });

    try {
      const resp = await next(req);

      this.notifications.update(id, {
        text: 'components:notifications.delete.success',
        type: 'success',
        autoCloseSeconds: 5,
        onClose: req.onNotificationRemove,
      });

      return resp;
    } catch (err: any) {
      const message = err.name === 'InvalidRequestError' && err.errors.code ? `platform:${err.errors.code}` : 'components:notifications.delete.failed';

      this.notifications.update(id, {
        text: message,
        type: 'error',
        autoCloseSeconds: 5,
        onClose: req.onNotificationRemove,
      });

      throw err;
    }
  }
}
