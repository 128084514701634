import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
import { withInject } from '@piwikpro/platform';
import {
  ToastContainer,
  addToast,
  updateToast,
  withTranslation,
} from '@piwikpro/ui-components';
import { Notifications } from './Notifications';
import { NotificationEvent } from './reducer';

export interface NotificationPanelProps {
  notifications: Notifications
  events: NotificationEvent[]
  t: any
}

export class NotificationsPanel extends Component<NotificationPanelProps> {
  componentDidUpdate() {
    const { events, notifications, t } = this.props;

    events.forEach((event) => {
      this.handleEvent(event, t);
      notifications.remove(event.notification.id);
    });
  }

  handleEvent(event: NotificationEvent, t: any) {
    const actions = event.notification.actions ? event.notification.actions.map(action => ({
      ...action,
      text: t(action.text),
    })) : undefined;
    const text = event.notification.text
      ? t(event.notification.text)
      : undefined;

    switch (event.type) {
      case 'ADD':
        addToast({
          ...event.notification,
          id: event.notification.id,
          text,
          actions,
        });
        break;
      case 'UPDATE':
        updateToast({
          ...event.notification,
          id: event.notification.id,
          text,
          actions,
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <ToastContainer t={t} />
    );
  }
}

export default withInject({
  notifications: 'NotificationCrate.notifications',
})(
  connect((state: any) => ({
    events: state.notifications.events,
  }))(
    withTranslation('profile')(NotificationsPanel),
  ),
);
