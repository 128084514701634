/* eslint-disable no-console */
import type { Console } from 'console';

export const LOG_LEVELS = {
  ERROR: 'error',
  WARNING: 'warn',
  DEBUG: 'debug',
  INFO: 'info',
};

export const LOG_GROUPS = {
  DEVELOPMENT: Object.values(LOG_LEVELS),
  PRODUCTION: [LOG_LEVELS.ERROR, LOG_LEVELS.WARNING],
  TESTING: [LOG_LEVELS.ERROR, LOG_LEVELS.WARNING],
};

export interface ILoggerOptions {
  group: string[]
}

export class Logger {
  private options: ILoggerOptions;

  constructor(private readonly console: Console, options = {}) {
    this.options = Object.assign({}, { group: LOG_GROUPS.PRODUCTION }, options);
  }

  debug(message: string): void {
    this.log(LOG_LEVELS.DEBUG, message);
  }

  info(message: string): void {
    this.log(LOG_LEVELS.INFO, message);
  }

  warn(message: string): void {
    this.log(LOG_LEVELS.WARNING, message);
  }

  error(message: string | Error): void {
    this.log(LOG_LEVELS.ERROR, message);
  }

  private log(level: string, message: string | Error): void {
    if (!this.options.group.includes(level)) return;

    (this.console as any)[level](message);
  }
}
