import React from 'react';
import { connect } from 'react-redux';
import { ConfigService, withInject } from '@piwikpro/platform';
import { Organization } from '@piwikpro/organization-crate';
import {
  SplashScreen,
  View,
  Root,
  Spinner,
} from '@piwikpro/ui-components';

const mapStateToProps = (state: any) => ({
  defaultModuleId: state.session.defaultModuleId,
  userId: state.session.userId,
});

interface Props {
  organization: Organization
  defaultModuleId: string
  config: ConfigService
  userId: string
  ProductBar: typeof React.Component
}

class DefaultModuleRedirector extends React.Component<Props> {
  async componentDidMount() {
    const {
      organization,
      defaultModuleId,
      config,
      userId,
    } = this.props;
    let redirectUrl;

    if (defaultModuleId === null) return;

    try {
      const {
        data: {
          attributes: {
            apps,
            common,
          },
        },
      } = await organization.fetchConfig(userId);

      redirectUrl = [...apps, ...common].find(
        element => element.id === defaultModuleId,
      ).href;
    } catch (err) {
      redirectUrl = config.get('DEFAULT_MODULE_URL_FALLBACK');
    }

    document.location.replace(`${document.location.origin}${redirectUrl}`);
  }

  render() {
    const {
      defaultModuleId,
      ProductBar,
    } = this.props;

    if (defaultModuleId === null) {
      return (
        <Root>
          <Root.TopSection>
            <ProductBar displayName="ProductBar" />
          </Root.TopSection>
          <View fullHeight>
            <SplashScreen type="noPrivilegesAllModules" />
          </View>
        </Root>
      );
    }

    return <Spinner className="x-large" type="fullscreen" />;
  }
}

export default withInject({
  organization: 'OrganizationCrate.organization',
  ProductBar: 'AuthCrate.components.ProductBar',
  config: 'config',
})(connect(mapStateToProps, null)(DefaultModuleRedirector));
