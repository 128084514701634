import { handleActions } from 'redux-actions';
import {
  titleChanged, TitleChanged,
} from './TitleService';

export interface TitleState extends TitleChanged {}

const initialState: TitleState = {
  title: 'Loading...',
  parts: [],
};

export default handleActions<TitleState>(
  {
    [titleChanged as any]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);
