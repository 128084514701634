import React, { useEffect } from 'react';
import { TFunction } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { History } from 'history';
import { withInject } from '@piwikpro/platform';
import {
  Layout,
  Card,
  Stack,
  StyledContainer,
  Text,
  Button,
  Icon,
  Label,
  Header,
  HelpLink,
  Tag,
  TextStyle,
  Spinner,
  withTranslation,
} from '@piwikpro/ui-components';
import { MarketingService } from '@piwikpro/marketing-crate';
import { Organization } from '../../Organization';
import LogoAndFaviconEdit from '../LogoAndFaviconEdit';

interface IFailMessageProps {
  t: (key: string) => string
}

export interface LogoAndFaviconProps {
  isCoreLicenseType: boolean
  isLoading: boolean
  failed: boolean
  isDefaultSetup: boolean
  organizationApi: Organization
  marketing: MarketingService
  history: History
  t: TFunction
}

const FailMessage = withTranslation(['administration'])(({ t }: IFailMessageProps) => (
  <Stack
    vertical
    horizontalAlignment="middle"
    verticalAlignment="center"
    spacing="extraWide"
  >
    <Stack.Item fill />
    <Stack.Item>
      <Stack
        vertical
        horizontalAlignment="middle"
      >
        <Stack.Item>
          <Icon
            color="red"
            name="exclamation-mark-circle"
            size="medium"
          />
        </Stack.Item>
        <Stack.Item>
          <TextStyle type="negative">
            {t('accountSecurity.twoFactorAuth.load.failed.description')}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Stack.Item>
    <Stack.Item fill />
  </Stack>
));

const InactiveState = withTranslation(['administration'])(({ t, marketing }: Pick<LogoAndFaviconProps, 'marketing' | 't'>) => (
  <StyledContainer backgroundColor="white" backgroundImage="image3" border={['top', 'right', 'bottom', 'left']} padding="wide">
    <Stack vertical horizontalAlignment="middle">
      <Stack.Item>
        <Icon size="large" name="custom-logo-favicon" color="blue" />
      </Stack.Item>
      <Stack.Item>
        <Label>
          {t('accountSettings.logoAndFavicon.inactive.label')}
        </Label>
      </Stack.Item>
      <Stack.Item previousSpacing="narrow">
        <span style={{ textAlign: 'center' }}>
          <Text>
            {t('accountSettings.logoAndFavicon.inactive.description')}
          </Text>
        </span>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          <Stack.Item>
            <Button
              onClick={() => {
                marketing.openProductPricingPage();
              }}
              text={t('platform.license.upgrade.button')}
            />
          </Stack.Item>
          <Stack.Item>
            <Button
              onClick={() => window.open('https://help.piwik.pro/support/account/add-a-custom-logo-and-favicon', '_blank')}
              text={t('accountSettings.logoAndFavicon.inactive.readMore')}
              appearance="simple"
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  </StyledContainer>
));

const Wrapper = withTranslation(['administration'])(({ t, children }: { t: TFunction, children: React.ReactNode }) => (
  <Layout dataId="LogoAndFaviconSettings">
    <Layout.AnnotatedSection
      title={t('accountSettings.logoAndFavicon.layout.title')}
    />
    <Layout.Column dominant>
      {children}
    </Layout.Column>
  </Layout>
));

export const LogoAndFavicon: React.FunctionComponent<LogoAndFaviconProps> = ({
  isCoreLicenseType,
  isLoading,
  failed,
  isDefaultSetup,
  organizationApi,
  marketing,
  history,
  t,
}) => {
  const { url } = useRouteMatch();
  useEffect(() => {
    organizationApi.fetchBranding().catch(() => {});
  }, []);

  if (isCoreLicenseType) {
    return (
      <Wrapper>
        <InactiveState marketing={marketing} />
      </Wrapper>
    );
  }
  if (isLoading || failed) {
    return (
      <Wrapper>
        <Card>
          <Card.Section>
            {isLoading && <Spinner />}
            {failed && <FailMessage />}
          </Card.Section>
        </Card>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Card>
        <Card.Section>
          <Stack vertical horizontalAlignment="middle">
            <Stack.Item fill>
              <Header>{t('accountSettings.logoAndFavicon.layout.section.header')}</Header>
              <Text>
                {t('accountSettings.logoAndFavicon.layout.section.text')}
              </Text>
            </Stack.Item>
            <Stack.Item fill>
              <Card>
                <Card.Section subdued condensed>
                  <Stack verticalAlignment="center">
                    <Stack.Item fill>
                      <Stack verticalAlignment="center" spacing="narrow">
                        <Stack.Item>
                          <Label>{t('accountSettings.logoAndFavicon.layout.settingsPreview.label')}</Label>
                        </Stack.Item>
                        <Stack.Item>
                          <Tag color={isDefaultSetup ? 'white' : 'green'} noMargin>
                            {t(`accountSettings.logoAndFavicon.layout.settingsPreview.settings.${isDefaultSetup ? 'default' : 'custom'}`)}
                          </Tag>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                    {!isDefaultSetup && (
                      <Stack.Item>
                        <Button
                          appearance="simple"
                          text={t('accountSettings.logoAndFavicon.layout.settingsPreview.settings.restoreDefault')}
                          onClick={async () => {
                            try {
                              await organizationApi.resetBranding();
                              setTimeout(() => window.location.reload(), 1000);
                            // eslint-disable-next-line no-empty
                            } catch {}
                          }}
                        />
                      </Stack.Item>
                    )}
                    <Stack.Item previousSpacing="narrow">
                      <Button
                        text={t('accountSettings.logoAndFavicon.layout.settingsPreview.settings.edit')}
                        onClick={() => history.push(`${url}/o/edit-logo`)}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              </Card>
            </Stack.Item>
            <Stack.Item>
              <HelpLink href="https://help.piwik.pro/support/account/add-a-custom-logo-and-favicon">
                Add a custom logo and favicon
              </HelpLink>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
      <Switch>
        <Route
          path={`${url}/o/edit-logo`}
          render={() => <LogoAndFaviconEdit />}
        />
      </Switch>
    </Wrapper>
  );
};

export default withInject({
  organizationApi: 'OrganizationCrate.organization',
  history: 'RouterCrate.history',
  marketing: 'MarketingCrate.marketingService',
})(withTranslation(['administration'])(LogoAndFavicon));
