import React from 'react';
import { connect } from 'react-redux';
import { withInject } from '@piwikpro/platform';
import { ProductBar } from '@piwikpro/ui-components';

const mapStateToProps = (state: any) => ({
  jwt: state.session.jwt,
  licenseType: state.licenseKeeper.license?.plan.type,
  email: state.session.email,
  trafficLimit: state.licenseKeeper.license?.plan.traffic_limit,
  userRole: state.session.userRole,
  licenseError: state.licenseKeeper.failed,
});

const ConnectedProductBar = connect(mapStateToProps, null)(ProductBar);
// wrapper for ProductBar with proper displayName
// to be able to display StatusBars from Root.TopSection component
// see: https://github.com/PiwikPRO/PPMS-UIComponents/pull/1559#pullrequestreview-984631456
const ProductBarComponent = withInject({
  session: 'AuthCrate.session',
})((props: any) => (
  // eslint-disable-next-line react/destructuring-assignment
  <ConnectedProductBar {...props} onLogout={() => props.session.logout()} />
));
ProductBarComponent.displayName = 'ProductBar';

export default ProductBarComponent;
