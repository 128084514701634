import * as Yup from 'yup';
import { regExps } from './regExps';

const isPiwikSupportUser = (email: string): boolean => {
  const [username, domain] = email.split('@');

  return username.includes('support+') && domain === 'piwik.pro';
};

export default (
  yup: typeof Yup,
  t: Function,
) => ({
  email: yup.string()
    .required()
    .test('email', t('administration:email.should.be.valid.form.info'), (value: any) => (
      regExps.email.test(value)
      && !isPiwikSupportUser(value)
    )),
  password: yup.string()
    .required()
    .matches(regExps.password, t('profile:password.should.contain.form.info')),
  passwordRepeated: yup.string()
    .required()
    .matches(regExps.password, t('profile:password.should.contain.form.info'))
    // ts can't handle below custom validator
    // @ts-ignore:next-line
    .equalTo(yup.ref('password'), t('profile:password.must.match')),
});
