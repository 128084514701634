import { Service, inject } from '@piwikpro/platform';
import { Interceptable, IRequest, NextFunction } from '../interfaces';
import {
  InvalidRequestError,
  InternalServerError,
  NotFoundError,
  ForbiddenError,
  UnauthorizedRequestError,
  ServiceUnavailableError,
} from '../httpErrors';

@Service()
export class FileInterceptor implements Interceptable {
  async intercept<T=any>(req: Required<IRequest>, next: NextFunction): Promise<T | null> {
    // to let browser define it by itself
    delete req.headers['Content-Type'];

    const resp = await next(req);

    if (!resp.ok) {
      switch (resp.status) {
        case 400:
          throw new InvalidRequestError('Invalid Request', []);
        case 404:
          throw new NotFoundError();
        case 403:
          throw new ForbiddenError('ForbiddenError');
        case 401:
          throw new UnauthorizedRequestError();
        case 500:
          throw new InternalServerError();
        case 503:
          throw new ServiceUnavailableError(resp.headers);
        default:
          throw new Error(resp.status);
      }
    }

    return resp;
  }
}
