/* eslint-disable no-param-reassign */
import { Middleware } from 'redux';
import {
  ContainerModule,
  interfaces,
  decorate,
  injectable,
} from 'inversify';
import { ComponentType } from 'react';
import { ConfigService } from './ConfigService';

/**
 * Register service dependencies in Dependency Injection container
 */
export interface IRegistry {
  (
    bind: interfaces.Bind,
    isBound: interfaces.IsBound,
    config: ConfigService
  ): void
}

export interface ICrateBaseSettings {
  name: string
  imports?: any
  panels?: Array<ComponentType<any>>
  registry?: IRegistry
  services?: any
  providers?: any
  reducers?: any
  components?: any
  reduxMiddleware?: Middleware[]
  bootstrap?: ComponentType<any>
}

export interface ICrateSettings extends ICrateBaseSettings {}

export interface IMainCrateSettings extends ICrateSettings {
  bootstrap: ComponentType<any>
}

export interface ICrate {
  onInit?(): Promise<void>
}

export interface ICrateConstructor<Settings> {
  settings: Settings
  new(): ICrate
  configure?(config: ConfigService): Promise<void>
  onInit: (config: ConfigService) => ContainerModule
}

export interface ICrateComponentProps {
  t: any
}

/**
 * Used to provide Crate configuration for Platform
 *
 * @returns {ClassDecorator} Decorator for dynamic crates
 */
export const Crate = (settings: ICrateSettings): ClassDecorator => (
  ((constructor: ICrateConstructor<ICrateSettings>): void => {
    decorate(injectable(), constructor);

    constructor.settings = settings;
  }) as ClassDecorator
);
