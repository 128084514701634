/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import TranslationCrate from '@piwikpro/translation-crate';
import NotificationsPanel from './NotificationsPanel';
import notifications from './reducer';
import { Notifications } from './Notifications';
import { NotifyOnFail } from './interceptors/NotifyOnFail';
import { NotifyAboutUpdate } from './interceptors/NotifyAboutUpdate';
import { NotifyAboutDelete } from './interceptors/NotifyAboutDelete';
import { NotifyAboutRemoval } from './interceptors/NotifyAboutRemoval';
import { NotifyAboutAddition } from './interceptors/NotifyAboutAddition';
import { NotifyAboutCreation } from './interceptors/NotifyAboutCreation';
import { CustomNotification } from './interceptors/CustomNotification';

@Crate({
  imports: [TranslationCrate],
  name: 'NotificationCrate',
  panels: [NotificationsPanel],
  reducers: {
    notifications,
  },
  services: [
    { name: 'notifications', provide: Notifications },
    { name: 'interceptors.NotifyOnFail', provide: NotifyOnFail },
    { name: 'interceptors.NotifyAboutUpdate', provide: NotifyAboutUpdate },
    { name: 'interceptors.NotifyAboutCreation', provide: NotifyAboutCreation },
    { name: 'interceptors.NotifyAboutDelete', provide: NotifyAboutDelete },
    { name: 'interceptors.NotifyAboutRemoval', provide: NotifyAboutRemoval },
    { name: 'interceptors.NotifyAboutAddition', provide: NotifyAboutAddition },
    { name: 'interceptors.CustomNotification', provide: CustomNotification },
  ],
})
export class NotificationCrate {}
