import React, { ReactNode } from 'react';
import { withInject } from '@piwikpro/platform';
import { TitleContext, TitleProvider, TitlePart } from './TitleProvider';
import { TitleService } from './TitleService';

interface InjectedProps {
  title: TitleService
}

export interface TitleProps extends InjectedProps {
  part?: string
  update?: boolean
  subset?: boolean
  important?: boolean
  children: ReactNode
}

export class Title extends React.Component<TitleProps> {
  static contextType = TitleContext;

  static defaultProps = {
    important: false,
    subset: false,
  }

  constructor(props: TitleProps, context: React.Context<TitleProps>) {
    super(props, context);

    const {
      title,
      part,
      subset,
    } = this.props;

    if (subset) {
      title.subset({ value: part, subset } as Required<TitlePart>);
    } else {
      title.update(this.getTitleParts());
    }
  }

  componentDidUpdate(prevProps: TitleProps) {
    const {
      title,
      part,
      subset,
    } = this.props;

    if (prevProps.part !== part) {
      if (subset) {
        title.subset({ value: part, subset } as Required<TitlePart>);
      } else {
        title.update(this.getTitleParts());
      }
    }
  }

  componentWillUnmount() {
    const {
      title,
      subset,
    } = this.props;

    if (subset) {
      title.subset();
    }
  }

  private getTitleParts() {
    const { part, important, subset } = this.props;
    const titleParts = this.context;

    return [...(titleParts as []), { value: part, important, subset }];
  }

  render() {
    const { children } = this.props;

    return (
      <TitleProvider parts={this.getTitleParts()}>
        {children}
      </TitleProvider>
    );
  }
}

export default withInject<InjectedProps>({
  title: 'TitleCrate.title',
})(Title);
