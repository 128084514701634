/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ getUserConfirmation: () => {} });

@Crate({
  name: 'RouterCrate',
  services: [
    { name: 'history', provide: history, type: 'constant' },
  ],
  reducers: {
    router: connectRouter(history),
  },
  reduxMiddleware: [routerMiddleware(history)],
})
export class RouterCrate { }
