import { Service, inject } from '@piwikpro/platform';
import { HttpClient, httpErrors, IRequest } from '@piwikpro/http-crate';
import { NotifyOnFail, NotifyAboutUpdate } from '@piwikpro/notification-crate';
import type { Store } from 'redux';
import {
  licenseFetchInitialized,
  licenseFetchSucceeded,
  licenseFetchFailed,
  licenseUploadInitialized,
  licenseUploadSucceeded,
  licenseUploadFailed,
  licenseDecryptInitialized,
  licenseDecryptSucceeded,
  licenseDecryptFailed,
} from './reducer';
import {
  LicenseStatus,
  Response,
} from './interfaces';

export const LICENSE_STATUSES = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  IS_FETCHING: 'fetching',
};

class NoLicenseError extends Error {
  name = 'NoLicenseError';

  errors = {};
}

@Service()
export class LicenseKeeper {
  constructor(
    @inject('HttpCrate.httpClient') private readonly httpClient: HttpClient,
    @inject('store') private readonly store: Store,
    @inject('config') private readonly config: any,
    @inject('NotificationCrate.interceptors.NotifyAboutUpdate') private readonly notifyAboutUpdateInterceptor: NotifyAboutUpdate,
    @inject('NotificationCrate.interceptors.NotifyOnFail') private readonly notifyOnFailInterceptor: NotifyOnFail,
  ) {}

  /**
   * Fetch license details
   *
   * @event Dispatch "licenseFetchInitialized" before request action
   * @event Dispatch "licenseFetchSucceeded" when respond with ok status code or user is OWNER
   * @event Dispatch "licenseFetchFailed" when respond with error status code
   *
   * @returns response with license details or throws an error
   */

  async fetchLicense(requestParams?: Partial<IRequest>): Promise<Response|Error> {
    this.store.dispatch(licenseFetchInitialized());

    try {
      const resp: Response = await this.httpClient.request({
        endpoint: `${this.config.get('LICENSE_KEEPER_URL')}`,
        method: 'GET',
        excludedResponseCodes: [404],
        ...requestParams,
      }).jsonApi().send();

      this.store.dispatch(licenseFetchSucceeded(resp));

      return resp;
    } catch (err: any) {
      this.store.dispatch(licenseFetchFailed(err.name));

      throw err;
    }
  }

  /**
   * Fetch license details for single module
   *
   * @param moduleName name of module
   *
   * @returns LicenseStatus object
   */

  async fetchLicenseForModule(moduleName: string): Promise<any|Error> {
    let response: Response;

    if (this.config.get('AVAILABLE_MODULES')[moduleName] === undefined) {
      throw new httpErrors.NotFoundError();
    }
    try {
      response = await this.httpClient.request({
        endpoint: `${this.config.get('LICENSE_KEEPER_URL')}`,
        method: 'GET',
      }).jsonApi().send();
    } catch (err: any) {
      if (err.name === 'NotFoundError' || err.name === 'ForbiddenError') {
        throw new NoLicenseError();
      } else {
        throw err;
      }
    }
    if (this.licenseIncludesModule(response.data.attributes.modules, moduleName)) {
      return this.checkLicenseStatus(response.data.attributes.status, moduleName);
    }

    throw new NoLicenseError();
  }

  /**
   * Checks license status and returns parsed LicenseStatus object
   *
   * @param status status of license
   * @param moduleName name of single module
   *
   * @returns LicenseStatus object
   */

  checkLicenseStatus(status: string, moduleName: string): LicenseStatus {
    const availableModules = this.config.get('AVAILABLE_MODULES');

    switch (status) {
      default:
      case LICENSE_STATUSES.INACTIVE:
        return {
          licenseStatus: LICENSE_STATUSES.INACTIVE,
          moduleName: availableModules[moduleName].label,
        };
      case LICENSE_STATUSES.ACTIVE:
      case LICENSE_STATUSES.EXPIRED:
        return {
          licenseStatus: LICENSE_STATUSES.ACTIVE,
          moduleName: availableModules[moduleName].label,
        };
    }
  }

  /**
   * Checks if given module exists in license resposne object
   *
   * @param modules object of modules
   * @param moduleName name of single module
   *
   * @returns boolean
   */

  licenseIncludesModule(modules: any, moduleName: string): boolean {
    let moduleExists = false;
    Object.keys(modules).forEach((singleModule) => {
      if (singleModule === this.config.get('AVAILABLE_MODULES')[moduleName].id) {
        moduleExists = true;
      }
    });

    return moduleExists;
  }

  /**
   * Uploads new license
   *
   * @event Dispatch "licenseUploadInitialized" before request action
   * @event Dispatch "licenseUploadSucceeded" when respond with ok status code or user is OWNER
   * @event Dispatch "licenseUploadFailed" when respond with error status code
   *
   * @param license new, encrypted license value
   *
   * @returns passed in license attributes or throws an error
   */

  async uploadLicense(license: string): Promise<string|Error> {
    this.store.dispatch(licenseUploadInitialized());

    try {
      await this.httpClient.request({
        endpoint: `${this.config.get('LICENSE_KEEPER_URL')}`,
        method: 'POST',
        body: {
          data: {
            attributes: {
              license,
            },
            type: 'ppms/encrypted-license',
          },
        },
      }).intercept(this.notifyAboutUpdateInterceptor).jsonApi().send();

      this.store.dispatch(licenseUploadSucceeded());

      return license;
    } catch (err) {
      this.store.dispatch(licenseUploadFailed());

      throw err;
    }
  }

  async decryptLicense(license: string): Promise<any> {
    this.store.dispatch(licenseDecryptInitialized());

    try {
      const response = await this.httpClient.request({
        endpoint: `${this.config.get('LICENSE_KEEPER_URL')}/decrypt`,
        method: 'POST',
        body: {
          data: {
            attributes: {
              license,
            },
            type: 'ppms/encrypted-license',
          },
        },
      }).intercept(this.notifyOnFailInterceptor).jsonApi().send();

      this.store.dispatch(licenseDecryptSucceeded());

      return response;
    } catch (err) {
      this.store.dispatch(licenseDecryptFailed());

      throw err;
    }
  }
}
