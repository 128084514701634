/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import type { Store } from 'redux';
import { Crate, inject } from '@piwikpro/platform';
import HttpCrate from '@piwikpro/http-crate';
import NotificationCrate from '@piwikpro/notification-crate';
import TranslationCrate from '@piwikpro/translation-crate';
import OrganizationCrate from '@piwikpro/organization-crate';
import RouterCrate from '@piwikpro/router-crate';
import { BroadcastChannelService } from '@piwikpro/ui-components';
import { Session, SessionSyncEvent } from './Session';
import { JWTTimer } from './JWTTimer';
import { Auth } from './interceptors/Auth';
import { JWTRevalidator } from './interceptors/JWTRevalidator';
import Secured from './Secured';
import ProductBar from './ProductBar';
import sessionReducer, { getSessionInitialized, getSessionSucceeded } from './reducers/session';
import authSettingsReducer from './reducers/auth';
import { AuthSettings } from './AuthSettings';

@Crate({
  name: 'AuthCrate',
  imports: [
    HttpCrate,
    RouterCrate,
    NotificationCrate,
    OrganizationCrate,
    TranslationCrate,
  ],
  services: [
    { name: 'session', provide: Session },
    { name: 'jwtTimer', provide: JWTTimer },
    { name: 'interceptors.Auth', provide: Auth },
    { name: 'interceptors.JWTRevalidator', provide: JWTRevalidator },
    { name: 'authSettings', provide: AuthSettings },
  ],
  registry: async (bind: any) => {
    bind('AuthCrate.sessionSyncChannel').toConstantValue(new BroadcastChannelService());
  },
  components: [
    { name: 'Secured', provide: Secured },
    { name: 'ProductBar', provide: ProductBar },
  ],
  reducers: {
    session: sessionReducer,
    authSettings: authSettingsReducer,
  },
})
export class AuthCrate {
  constructor(
    @inject('AuthCrate.session') private session: Session,
    @inject('AuthCrate.sessionSyncChannel') private sessionSyncChannel: BroadcastChannelService,
    @inject('store') private readonly store: Store,
  ) {
    this.sessionSyncChannel.registerMessageListener((event) => {
      const { data: { name, payload } } = event as SessionSyncEvent;

      if (name === 'token-updated') {
        this.store.dispatch(getSessionInitialized());
        this.store.dispatch(getSessionSucceeded({
          ...this.store.getState().session,
          token: payload!.token,
          session_expiration: payload!.sessionExpDate,
        }));
        this.session.setJwtToken(payload!.token);
      } else if (event.data.name === 'logout') {
        this.session.logout({
          withSyncMsg: false,
        });
      }
    });
  }
}
