/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { Crate } from '@piwikpro/platform';
import TranslationCrate from '@piwikpro/translation-crate';
import FormCrate from '@piwikpro/form-crate';
import confirmation from './redux';
import { Confirmation } from './Confirmation';
import ConfirmationPanel from './ConfirmationPanel';

@Crate({
  name: 'ConfirmationCrate',
  imports: [
    TranslationCrate,
    FormCrate,
  ],
  panels: [ConfirmationPanel],
  reducers: {
    confirmation,
  },
  services: [
    { name: 'confirmation', provide: Confirmation },
  ],
})
export class ConfirmationCrate {}
